import { Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from './core/services/language.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { RecoveryService } from './core/services/recovery.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
    private backgroundMode: BackgroundMode,
    private recoveryService: RecoveryService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // let status bar overlay webview
      this.statusBar.overlaysWebView(false);
      // set status bar to white
      this.statusBar.backgroundColorByHexString('#FFFFFF');
      this.statusBar.styleDefault();

      this.languageService.setInitialAppLanguage();
      this.backgroundMode.disable();

      this.platform.backButton.subscribeWithPriority(1, () => {
        // to disable hardware back button on whole app
      });

      this.splashScreen.hide();
    });

    if (this.platform.is('android')) {
      this.platform.resume.subscribe((event:any) => {
        this.handleAndroidCameraRestart(event)
      });
    }
  }

  private handleAndroidCameraRestart(event: any) {
    if (event && event.pendingResult) {
      const status: string = event.pendingResult.pluginStatus !== null ? '' : event.pendingResult.pluginStatus.toUpperCase();
      if ('Camera' === event.pendingResult.pluginServiceName && 'OK' !== status && event.pendingResult.result !== '') {
        // TODO: Automatizzare il recupero della foto e l'aggiornamento dell'entità.
        // console.log('event.pendingResult', event.pendingResult);
      }
    }
 }
}
