import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { } from '@ionic/angular';
import { Map, CRS, latLng, tileLayer, Layer, imageOverlay, svgOverlay, marker, icon } from 'leaflet';
import { UserService } from 'src/app/core/services/user.service';
import { BoatsService, BoatModel } from 'src/app/core/services/boats.service';
import { DocumentsService, DocumentModel } from 'src/app/core/services/documents.service';
import { take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { SectionModel } from 'src/app/core/services/sections.service';
import * as _ from 'lodash';
import domtoimage from 'dom-to-image';
import { Utilities } from './../../../common/utilities.class';

@Component({
  selector: 'app-print-task-map',
  templateUrl: './print-task-map.component.html',
  styleUrls: ['./print-task-map.component.scss'],
})
export class PrintTaskMapComponent implements OnInit {
  constructor(
    private userService: UserService,
    private boatService: BoatsService,
    private documentService: DocumentsService,

  ) { }

  public map: Map;
  private accesstoken;
  private loaded = false;
  public  image = '';
  public mapCss = '';
  public taskIsOpen = null;
  public taskStatus = null;
  private marker = icon({
    // iconUrl: '/assets/icon/pin-poi-grey.png',
    iconUrl: '/assets/storm-pins/Location/Active.svg',
    shadowUrl: '',
    iconSize: [32, 32], // size of the icon
    shadowSize: [0, 0], // size of the shadow
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor,
    html: 'this is a test'
  });
  
  @Input() lat;
  @Input() lng;
  @Input() boatSection;
  @Input() width;
  @Input() height;
  @Input() zoom;
  @Input() set status(val: string) {
    this.taskStatus = (val !== undefined && val !== null) ? val : null;
  }
  @Input() set isOpen(val: boolean) {
    this.taskIsOpen = (val !== undefined && val !== null) ? val : null;
  }

  ngOnInit() {
    this.userService.getToken().pipe(
      take(1)
    ).subscribe(accesstoken => {
      this.accesstoken = accesstoken;
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accesstoken });
      this.boatService.headers = headers;
      this.documentService.headers = headers;
    });
    this.loaded = false;
    this.createMap();
    // tslint:disable-next-line: max-line-length
    this.marker.iconUrl = (this.taskStatus !== null && this.taskIsOpen !== null) ? Utilities.getIconPath(this.taskStatus, this.taskIsOpen) : this.marker.iconUrl;
  }

  createMap(): Map {
    const map = new Map('map', {preferCanvas: true});
    map.options.crs = CRS.Simple;
    map.options.minZoom = -5;
    map.options.maxZoom = 5;
    this.getImageMeta(this.boatSection, (width, height) => {
      const bounds = [[0, 0], [height, width]];
      map.fitBounds(bounds);
      map.setView([this.lat, this.lng], this.zoom);
      /* caso immagine png o jpg */
      imageOverlay(this.boatSection, bounds).addTo(map).on('load', (event) => {
       marker([this.lat, this.lng], { icon: this.marker}).addTo(map);
       this.map = map;
       this.loaded = true;
       return map;
      }).on('error', (event) => {
        console.log(event);
      });
    });
  }

  /**
   * Renderizza la mappa
   * @param map
   */
  renderMap(map: Map) {
    const mapContainer = map.getContainer();
    this.loaded  = false;
    const originalState = {
      mapWidth: mapContainer.style.width,
      widthWasAuto: false,
      widthWasPercentage: false,
      mapHeight: mapContainer.style.height,
      zoom: map.getZoom(),
      center: map.getCenter()
    };
    domtoimage.toPng(mapContainer, {
      width: 960,
      height: 350,
    })
    .then((dataUrl) => {
      // const blob = this.dataURItoBlob(dataUrl); // da dataUri a file blob utile se si vuole produrre l,anteprima
      this.image = dataUrl;
      this.loaded = true;
    })
    .catch((error) => {
        console.error('oops, something went wrong!', error);
    });
  }
 
  public getMapImage() {
    return this.image;
    
  }



  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const dw = new DataView(ab);
    for(let i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i));
    }
    return new Blob([ab], {type: mimeString});
  }

  getImageMeta(url, callback) {
    const img = new Image;
    img.src = url; //'https://splashbase.s3.amazonaws.com/unsplash/regular/tumblr_mtax0twHix1st5lhmo1_1280.jpg';//url;
    img.onload = () => { callback(img.width, img.height); };
  }

}