import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const defaultItem = {
  name: 'Document n.x',
  link: '',
  upload_date: ''
};

@Component({
  selector: 'n7-document-preview',
  templateUrl: './n7-document-preview.component.html',
  styleUrls: ['./n7-document-preview.component.scss'],
})
export class N7DocumentPreviewComponent implements OnChanges {
  public item: any;
  public itemName: string;

  @Input() set document(val: any) {
    this.item = (val !== undefined && val !== null) ? val : defaultItem;
  }

  constructor(
    private iab: InAppBrowser
  ) {
    this.itemName = 'Creating report...';
  }

  ngOnChanges() {
    // console.log('ngOnChanges', this.item);
    if (this.item && this.item.id && this.item.title) {
      switch (this.item.title) {
        case 'environmental_report.docx':
          this.itemName = 'Environmental report' + ' n.' + this.item.id;
          break;

        case 'corrosion_map.docx':
          this.itemName = 'List of points' + ' n.' + this.item.id;
          break;

        default:
          this.itemName = 'Document' + ' n.' + this.item.id;
          break;
      }
    }
    else {
      this.itemName = 'Creating report...';
    }
  }

  onItemClick(item) {
    // console.log('item', item);
  }

  onItemLinkClick(item) {
    // console.log('item', item);
    const url = item.link;
    // window.open(url, "_blank");
    this.iab.create(url, '_system');
  }
}
