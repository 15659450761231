
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   api: {
//     client_id: 2,
//     client_secret: 'HiIrGzZSrUggBh6ye56v4g8gGr1i2oO9wiAOkbgk',
//     oauth_base_url: 'http://storm.zoba/oauth/',
//     auth_base_url: 'http://storm.zoba/api/auth/',
//     base_url: 'http://storm.zoba/api/',
//     domain: 'http://storm.zoba/',
//     version: 'v1'
//   },
// };


// export const environment = {
//   production: false,
//   settings: {
//     hasRemarkFunctionality: true,
//     hasApplicationFunctionality: true,
//     hasManagementFunctionality: true,
//     hasSurfaceFunctionality: true
//   },
//   api: {
//     client_id: 2,
//     client_secret: '4S8YDK6BlEkEN6oNlmEFoOVmbAJTRrvI17SBuHUX',
//     oauth_base_url: 'https://storm.staging.netseven.it/oauth/',
//     auth_base_url: 'https://storm.staging.netseven.it/api/auth/',
//     base_url: 'https://storm.staging.netseven.it/api/',
//     domain: 'https://storm.staging.netseven.it/',
//     version: 'v1'
//   },
//   drive: {
//     clientId: '596529921917-93tqgaqpttb9ap74lmd1uub2njfirp53.apps.googleusercontent.com',
//     apiKey: 'AIzaSyBNhq77cnK1on-suzwXVVCL71hc69z9YYs',
//     appId: '596529921917',
//     scope: 'https://www.googleapis.com/auth/drive.file',
//     parentFolder: 'root'
//   }
// };

export const environment = {
  production: true,
  settings: {
    hasRemarkFunctionality: true,
    hasApplicationFunctionality: true,
    hasManagementFunctionality: true,
    hasSurfaceFunctionality: true
  },
  api: {
    client_id: 2,
    client_secret: '4S8YDK6BlEkEN6oNlmEFoOVmbAJTRrvI17SBuHUX',
    oauth_base_url: 'https://backend.stormyachts.app/oauth/',
    auth_base_url: 'https://backend.stormyachts.app/api/auth/',
    base_url: 'https://backend.stormyachts.app/api/',
    domain: 'https://backend.stormyachts.app/',
    version: 'v1'
  },
  drive: {
    clientId: '596529921917-93tqgaqpttb9ap74lmd1uub2njfirp53.apps.googleusercontent.com',
    apiKey: 'AIzaSyBNhq77cnK1on-suzwXVVCL71hc69z9YYs',
    appId: '596529921917',
    scope: 'https://www.googleapis.com/auth/drive.file',
    parentFolder: 'root'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
