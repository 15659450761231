import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject } from '@angular/core';

@Component({
  selector: 'n7-select',
  templateUrl: './n7-select.component.html',
  styleUrls: [
    './n7-select.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7SelectComponent implements OnChanges {
  private _value: any;
  public label: string;
  public isOpen: boolean;

  _options = [];
  _placeholder = '';
  _backdrop = true;
  _themeSecondary = false;
  _showPlaceholder = false;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  @Input() set options(val: Array<any>) {
    this._options = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set value(val: any) {
    this._value = (val !== undefined && val !== null) ? val : {};
  }

  @Input() set placeholder(val: string) {
    this._placeholder = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set backdrop(val: boolean) {
    this._backdrop = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set themeSecondary(val: boolean) {
    this._themeSecondary = val !== undefined && val !== null ? val : false;
  }

  @Output() onChange = new EventEmitter();

  ngOnChanges() {
    this.label = (Object.entries(this._value).length === 0 && this._value.constructor === Object) ? this._placeholder : this._value.label;
    if(Object.entries(this._value).length === 0 && this._value.constructor === Object)
      this._showPlaceholder=true;
    this.isOpen = false;
  }

  onToggleEvent() {
    this.isOpen = !this.isOpen;
  }

  onChangeEvent(option: any) {
    this.isOpen = false;
    this._value = option;
    this.label = option.label;
    this._showPlaceholder=false;
    this.onChange.emit(option);
  }

  public refresh() {
    this.label = (Object.entries(this._value).length === 0 && this._value.constructor === Object) ? this._placeholder : this._value.label;
    if(Object.entries(this._value).length === 0 && this._value.constructor === Object)
      this._showPlaceholder=true;
  }
}
