import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-st-daterange-picker',
  templateUrl: './st-daterange-picker.component.html',
  styleUrls: ['./st-daterange-picker.component.scss'],
})
export class StDaterangePickerComponent implements OnInit {
  itemThemeSmall: boolean;
  selected: any;
  itemInitialStart: string;
  itemInitialEnd: string;

  @Input() set themeSmall(val: boolean) {
    this.itemThemeSmall = val !== undefined && val !== null ? val : false;
  }

  @Input() set initialStart(val: string) {
    this.itemInitialStart = val !== undefined && val !== null ? val : null;
  }

  @Input() set initialEnd(val: string) {
    this.itemInitialEnd = val !== undefined && val !== null ? val : null;
  }

  @Output() rangeChange = new EventEmitter()

  ranges: any = {
    // 'Today': [moment(), moment()],
    // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  constructor() {
    this.selected = {
      start: moment().subtract(29, 'days'),
      end: moment()
    };
  }

  ngOnInit() { }

  ngOnChanges() {
    this.selected = {
      start: (this.itemInitialStart) ? moment(this.itemInitialStart) : moment().subtract(29, 'days'),
      end: (this.itemInitialEnd) ? moment(this.itemInitialEnd) : moment()
    };
  }

  changeRange(e) {
    this.rangeChange.emit(e);
  }
}
