import { Component, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, Platform } from '@ionic/angular';
import { N7ModalConfirmComponent } from '../n7-modal-confirm/n7-modal-confirm.component'
import { N7ModalGalleryComponent } from '../n7-modal-gallery/n7-modal-gallery.component';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import * as uuid from 'uuid';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-photo-loader',
  templateUrl: './n7-photo-loader.component.html',
  styleUrls: ['./n7-photo-loader.component.scss'],
})
export class N7PhotoLoaderComponent implements OnChanges {
  @ViewChild("file", { read: ElementRef }) private itemFile: ElementRef;

  public index: number;
  public name: string;
  public selfRef: N7PhotoLoaderComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemId: string;
  public itemLabel: string;
  public itemHint: string;
  public itemHasError: boolean;
  public itemError: string;
  public itemFiles: string[];
  public itemValues: any[];
  public itemMax: number;
  public itemType: string = 'base64';
  public itemCanRemove: boolean = true;
  public itemGenerateDocumentID: boolean = true;
  public imageIndexer: number[];
  public itemRatio: any;

  @Input() set id(val: string) {
    this.itemId = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set values(val: string[]) {
    this.itemValues = (val !== undefined && val !== null) ? val.slice(0) : [];
  }

  @Input() set max(val: number) {
    this.itemMax = (val !== undefined && val !== null) ? val : 1;
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : 'base64';
  }

  @Input() set canRemove(val: boolean) {
    this.itemCanRemove = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set generateDocumentID(val: boolean) {
    this.itemGenerateDocumentID = (val !== undefined && val !== null) ? val : true;
  }

  @Output() valuesChange = new EventEmitter()

  constructor(
    public platform: Platform,
    private camera: Camera,
    private base64: Base64,
    private webview: WebView,
    public modalController: ModalController,
    private domSanitizer: DomSanitizer
  ) {
    this.imageIndexer = [];
    this.itemRatio = { w: 1, h: 1 };
    this.itemFiles = Array(this.itemMax).fill('');
  }

  ngOnChanges() {
    this.imageIndexer = Array(this.itemMax).fill('').map((x, i) => i);
    this.itemFiles = Array(this.itemMax).fill('');

    if (this.itemMax > 1) {
      this.itemRatio = { w: 1, h: 1 };
    }
    else {
      this.itemRatio = { w: 2, h: 1 };
    }

    // console.log('itemValues', this.itemValues);
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  onItemsClear(event) {
    this.itemValues = [];
    this.itemFiles = [];
    this.itemHasError = false;
    this.itemError = '';
    this.valuesChange.emit(this.itemValues);
  }

  onItemClear(index, event) {
    this.presentItemClearConfirmModal(index, event);
  }

  async presentItemClearConfirmModal(index, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Remove photo',
        message: 'Do you want to remove the selected image?',
        dismissLabel: 'Cancel',
        confirmLabel: 'Yes, remove'
      },
      cssClass: 'modal-confirm'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          if ((index > -1) && (index < this.itemMax)) {
            this.itemValues.splice(index, 1);
            this.itemFiles[index] = '';
            this.valuesChange.emit(this.itemValues);
          }
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  getBase64(index, file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      let encoded = reader.result.toString();
      const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null

      switch (this.itemType) {
        case 'document':
          let base64FileReplaced = encoded.replace(/(\r\n\t|\n|\r\t)/gm, '');
          let trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
          this.itemValues[index] = {
            type: 'documents',
            id: document_id,
            attributes: {
              doc_type: 'detailed_image',
              base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
            }
          }
          break;

        case 'base64':
        default:
          this.itemValues[index] = encoded;
      }

      // this.itemValues[index] = encoded;
      this.valuesChange.emit(this.itemValues);
    },
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
  }

  onLoadFile(index, event) {
    if (event.target.files[0] && event.target.files[0].name) {
      this.getBase64(index, event.target.files[0]);
    }
  }

  addPhoto(index) {
    // console.log('addPhoto', index);
    this.platform.ready().then(() => {
      if (this.platform.is('ios') || this.platform.is('android')) {
        const options: CameraOptions = {
          sourceType: this.camera.PictureSourceType.CAMERA,
          quality: 90,
          targetWidth: 1200,
          targetHeight: 800,
          destinationType: (this.platform.is('ios')) ? this.camera.DestinationType.DATA_URL : this.camera.DestinationType.FILE_URI,
          encodingType: this.camera.EncodingType.JPEG,
          allowEdit: false,
          correctOrientation: true,
          saveToPhotoAlbum: false,
          mediaType: 0
        };
        this.takePicture(index, options);
      }
      else {
        const file: any = document.getElementById('item-file-image_' + this.itemId + '__' + index);
        file.click();
      }
    });
  }

  uploadPhoto(index) {
    // console.log('uploadPhoto', index);
    this.platform.ready().then(() => {
      if (this.platform.is('ios') || this.platform.is('android')) {
        // SAVEDPHOTOALBUM
        // PHOTOLIBRARY
        const options: CameraOptions = {
          sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
          quality: 90,
          targetWidth: 1200,
          targetHeight: 800,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          allowEdit: false,
          saveToPhotoAlbum: false,
          mediaType: 0
        };
        this.takePicture(index, options);
      }
      else {
        const file: any = document.getElementById('item-file-image_' + this.itemId + '__' + index);
        file.click();
      }
    });
  }

  takePicture(index, options) {
    console.log('takePicture');

    // this.takingPicture = true;
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.camera.getPicture(options).then((imageData) => {
          console.log('imageData DATA_URL', imageData);
          if (this.camera.DestinationType.DATA_URL === options.destinationType) {
            // this.itemValues[index] = 'data:image/*;charset=utf-8;base64,' + imageData;
            // this.itemValues[index] = 'data:image/jpeg;base64,' + imageData;
            // this.valuesChange.emit(this.itemValues);

            let trustUrl = null;
            console.log('taking picture');
            const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
            switch (this.itemType) {
              case 'document':
                trustUrl = this.domSanitizer.bypassSecurityTrustUrl(imageData);
                this.itemValues[index] = {
                  type: 'documents',
                  id: document_id,
                  attributes: {
                    doc_type: 'detailed_image',
                    base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                  }
                }
                break;

              case 'base64':
              default:
                trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
                this.itemValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
            }

            // this.itemValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);

            // this.itemValues[index] = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAABhUlEQVR42u3TAREAAAQEMJKL/gpI4LYO61SmgFMLAoKAICAICAKCgCAgCAgCggCCgCAgCAgCgoAgIAgIAoKAIIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICAIIAgIAoKAICAICAKCgCAgCCAICAKCgCAgCAgCgoAgIAgIAggCgoAgIAgIAoKAICAICAIIAoKAICAICAKCgCAgCAgCggCCgCAgCAgCgoAgIAgIAoKAIIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICCIICAICAKCgCAgCAgCgoAgIAggCAgCgoAgIAgIAoKAICAICAIIAoKAICAICAKCgCAgCAgCCAKCgCAgCAgCgoAgIAgIAoIAgoAgIAgIAoKAICAICAKCAIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICCIICAICAKCgCAgCAgCgoAgIAggCAgCgoAgIAgIAoKAICAICCIICAKCgCAgCAgCgoAg8NEC3a/ygGAZNigAAAAASUVORK5CYII=";
            console.log('itemValues[index]', this.itemValues[index]);
            this.onSavePicture();
          }
          else {
            console.log('imageData', imageData);
            const imagePath = (this.platform.is('ios')) ? this.webview.convertFileSrc(imageData) : imageData;
            console.log('imagePath', imagePath);
            this.base64.encodeFile(imagePath).then((base64File: string) => {
              console.log('base64File', base64File);
              let base64FileReplaced = base64File.replace(/(\r\n\t|\n|\r\t)/gm, '');
              console.log('base64File', base64File);
              // this.itemValues[index] = base64File;
              // this.itemValues[index] = 'data:image/jpeg;base64,' + (base64File.replace(/^data:(.*,)?/, ''));
              // let trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + (base64FileReplaced.replace(/^data:(.*,)?/, '')));
              // this.itemValues[index] = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this.domSanitizer.bypassSecurityTrustResourceUrl(base64File));
              // this.itemValues[index] = this.domSanitizer.bypassSecurityTrustUrl("'" + base64File + "'");

              let trustUrl = null;
              console.log('taking picture');
              const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
              switch (this.itemType) {
                case 'document':
                  trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
                  this.itemValues[index] = {
                    type: 'documents',
                    id: document_id,
                    attributes: {
                      doc_type: 'detailed_image',
                      base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                    }
                  }
                  break;

                case 'base64':
                default:
                  trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + (base64FileReplaced.replace(/^data:(.*,)?/, '')));
                  this.itemValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
              }

              // this.itemValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);

              // this.itemValues[index] = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAABhUlEQVR42u3TAREAAAQEMJKL/gpI4LYO61SmgFMLAoKAICAICAKCgCAgCAgCggCCgCAgCAgCgoAgIAgIAoKAIIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICAIIAgIAoKAICAICAKCgCAgCCAICAKCgCAgCAgCgoAgIAgIAggCgoAgIAgIAoKAICAICAIIAoKAICAICAKCgCAgCAgCggCCgCAgCAgCgoAgIAgIAoKAIIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICCIICAICAKCgCAgCAgCgoAgIAggCAgCgoAgIAgIAoKAICAICAIIAoKAICAICAKCgCAgCAgCCAKCgCAgCAgCgoAgIAgIAoIAgoAgIAgIAoKAICAICAKCAIKAICAICAKCgCAgCAgCgoAggCAgCAgCgoAgIAgIAoKAICCIICAICAKCgCAgCAgCgoAgIAggCAgCgoAgIAgIAoKAICAICCIICAKCgCAgCAgCgoAg8NEC3a/ygGAZNigAAAAASUVORK5CYII=";
              console.log('itemValues[index]', this.itemValues[index]);
              this.onSavePicture();
            }, (err) => {
              // this.takingPicture = false;
              this.error = err;
              // this.presentErrors(err);
              console.log('error', err);
            }).catch(err => {
              // this.takingPicture = false;
              this.error = err;
              // this.presentErrors(err);
              console.log('error', err);
            });
          }
        }, (err) => {
          // this.takingPicture = false;
          this.error = err;
          // this.presentErrors(err);
          console.log('error', err);
        }).catch(err => {
          // this.takingPicture = false;
          this.error = err;
          // this.presentErrors(err);
          console.log('error', err);
        });
      }
    });
  }

  onSavePicture() {
    // console.log('savePicture');
    this.valuesChange.emit(this.itemValues);

    // const type = null;
    // if (type === null) {
    //   this.currentEditingTask.meta = { detailed_images: [], additional_images: [] };
    //   this.currentEditingTask.meta.detailed_images.push(this.picture);
    // } else {
    //   this.currentEditingTask.meta[type].push(this.picture);
    // }
    // this.userService.setValue(this.currentEditingTask.id, this.currentEditingTask);
    // this.addPointMode = false;
    // this.coords = null;
    // this.router.navigateByUrl('add-draft-draw/' + this.currentEditingTask.id);
  }

  onImageClick(index) {
    this.presentGalleryModal(index, {});
  }

  async presentGalleryModal(index, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalGalleryComponent,
      componentProps: {
        itemType: this.itemType,
        items: this.itemValues,
        index: index
      },
      cssClass: 'modal-fullscreen'
    });

    modal.onDidDismiss().then((data) => {
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

}
