import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';

import { environment } from '../../environments/environment';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryMyMarinaService } from '../core/in-memory-data.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    !environment.production ?
      InMemoryWebApiModule.forRoot(InMemoryMyMarinaService, {
        delay: 200,
        passThruUnknownUrl: true
      }) : []
  ],
  providers: []
})

export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
