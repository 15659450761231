import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
  } from '@ngrx/store';
import { storeLogger } from 'ngrx-store-logger';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';

import * as fromUser from './user/user.reducer';
import * as fromBoat from './boat/boat.reducer';

export interface AppState {
  user: fromUser.UserState;
  boat: fromBoat.BoatState;
}
export const appReducer: ActionReducerMap<AppState> = {
  user: fromUser.reducer,
  boat: fromBoat.reducer
};

export function logger(reducer: ActionReducer<AppState>): any {
  // default, no options
  return storeLogger()(reducer);
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(
    {
      keys: ['user'],
      rehydrate: true
    }
  )(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger, localStorageSyncReducer] : [localStorageSyncReducer];

export const getUserState = (state: AppState) => state.user;

export const getUserToken = createSelector(
  getUserState,
  fromUser.getToken
);

export const getUserData = createSelector(
  getUserState,
  fromUser.getUser
);

export const getUserLoading = createSelector(
  getUserState,
  fromUser.isLoading
);

export const getShowedHints = createSelector(
  getUserState,
  fromUser.getShowedHints
);

// Boat
export const getBoatState = (state: AppState) => state.boat;

export const getBoatFilters = (state: AppState) => state.boat.filters;
export const getBoatTasks = (state: AppState) => state.boat.tasks;

export const getBoatLoading = createSelector(
  getBoatState,
  fromBoat.isLoading
);
