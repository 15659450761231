import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject } from '@angular/core';
import { } from '@ionic/angular';
import * as L from 'leaflet';
import { Map, CRS, latLng, tileLayer, Layer, imageOverlay, svgOverlay, marker, icon } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-easyprint';

@Component({
  selector: 'n7-leaflet-map',
  templateUrl: './n7-leaflet-map.component.html',
  styleUrls: [
    './n7-leaflet-map.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7LeafletMapComponent implements OnChanges {
  public container_id: string;
  _container = 'map-container';
  _image = '';
  _svg = '';

  _bounds = [];
  _center = [0, 0];
  _zoom = 0;
  _markers = [];
  _icons = [];

  public mapUseCluster: boolean = true;

  private map: Map;
  //private miniature: Map;
  private markersRef = [];
  private clusterMarkers: L.markerClusterGroup = null;
  private printer;
  // TODO: Parametro del componente.
  private customIcon = icon({
    // iconUrl: '/assets/icon/pin-poi-grey.png',
    iconUrl: '/assets/storm-pins/Draft/Active.svg',
    shadowUrl: '',
    iconSize: [32, 32], // size of the icon
    shadowSize: [0, 0], // size of the shadow
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor,
    html: 'this is a test'
  });

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) { }

  private i = 0;

  @Input() set container(val: string) {
    this._container = (val !== undefined && val !== null) ? val : '';
    this.container_id = 'map-container__' + this._container;
  }

  @Input() set image(val: string) {
    this._image = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set svg(val: string) {
    this._svg = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set bounds(val: Array<any>) {
    this._bounds = (val !== undefined && val !== null) ? val : [[0, 0], [0, 0]];
  }

  @Input() set center(val: Array<number>) {
    this._center = (val !== undefined && val !== null) ? val : [0, 0];
  }

  @Input() set zoom(val: number) {
    this._zoom = (val !== undefined && val !== null) ? val : 16;
  }

  @Input() set markers(val: Array<any>) {
    this._markers = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set icons(val: Array<any>) {
    this._icons = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set useCluster(val: boolean) {
    this.mapUseCluster = (val !== undefined && val !== null) ? val : true;
  }

  @Output() mapClick = new EventEmitter();
  @Output() markerClick = new EventEmitter();
  @Output() onMarkerMouseover = new EventEmitter();


  ngOnChanges() {
    this.refreshMarkers();
    if (this._markers && this._markers.length) {
      setTimeout(() => {
        this.refreshMarkers();
      }, (500 + (this._markers.length * 10)));
    }
  }

  ngOnInit() {
    // this.leafletMap();
    setTimeout(() => {
      this.leafletMap();
    }, 100);
  }

  async getImageMeta(url, callback) {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image;
        img.src = url;
        img.onload = () => {
          callback(img.width, img.height);
          resolve();
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  getMapImage() {
    return this._image;
  }



  async leafletMap() {
    // console.log('this._container', this._container);
    this.map = new Map(this.container_id);
   // this.miniature = new Map('miniature');
    this.map.options.crs = CRS.Simple;
   // this.miniature.options.crs = CRS.Simple;
    // this.map.options.minZoom = -1;
    this.map.options.minZoom = -5;
    this.map.options.maxZoom = 5;

   // this.miniature.options.minZoom = -5;
   // this.miniature.options.maxZoom = 5;
    //this.miniature.setView( this.map.getCenter(), this._zoom);
    const custom = {
      width: 1754, //3508,
      height: 1240,
      className: 'a3CssClass',
      tooltip: 'A custom A3 size'
    };

   /*  this.printer = L.easyPrint({
      sizeModes: [custom],
      filename: 'myMap',
      exportOnly: true,
      hideControlContainer: true,
    }).addTo(this.miniature); */

    // console.log('SVG ', this._svg);
    if (this._svg.length > 0) {
      // console.log('SVG option');
      var svgWrapperElement = document.createElement('div');
      svgWrapperElement.innerHTML = this._svg;

      if (svgWrapperElement.getElementsByTagName('svg')[0]) {
        let xmlns = svgWrapperElement.getElementsByTagName('svg')[0].getAttribute('xmlns');
        let viewBox = svgWrapperElement.getElementsByTagName('svg')[0].getAttribute('viewBox');
        let svgContent = svgWrapperElement.getElementsByTagName('svg')[0].innerHTML;

        // console.log('svgWrapperElement', svgWrapperElement);
        // console.log('svgWrapperElement xmlns', xmlns);
        // console.log('svgWrapperElement viewBox', viewBox);
        // console.log('svgWrapperElement svgContent', svgContent);
        if ((xmlns.length > 0) && (viewBox.length > 0) && (svgContent.length > 0)) {
          var svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          svgElement.setAttribute('xmlns', xmlns);
          svgElement.setAttribute('viewBox', viewBox);
          svgElement.innerHTML = svgContent;

          let bounds = viewBox.split(' ');
          if (bounds.length === 4) {
            // tslint:disable-next-line: max-line-length
            this._bounds = [[Math.ceil(parseFloat(bounds[0])), Math.ceil(parseFloat(bounds[1]))], [Math.ceil(parseFloat(bounds[2])), Math.ceil(parseFloat(bounds[3]))]];
          }
          svgOverlay(svgElement, this._bounds).addTo(this.map).on('load', (event) => {
            this.loaded(true);
          }).on('error', (event) => {
            this.error(true);
          });
        }
      }
    }

    if (this._image.length > 0) {
      // console.log('IMAGE ', this._image);

      let self = this;
      await this.getImageMeta(this._image, function (width, height) {
        // console.log(width + 'px ' + height + 'px');
        self._bounds = [[0, 0], [height, width]];
      });
      // console.log('this._bounds', this._bounds);

      imageOverlay(this._image, this._bounds).addTo(this.map).on('load', function (event) {
        self.loaded(true);
      }).on('error', function (event) {
        self.error(true);
      });

      setTimeout(() => {
        // console.log('this._bounds', this._bounds);
        // imageOverlay(this._image, this._bounds).addTo(this.miniature);
      }, 50);
    }
    // console.log('this._bounds', this._bounds);
    this.map.fitBounds(this._bounds);
    this.map.on('click', (e) => this.onMapClickEvent(e));

    // setTimeout(() => {
    //   // this.map.fitBounds(this._bounds);
    //   //this.miniature.setView( this.map.getCenter(), this._zoom);
    //   this.map.fitBounds(this._bounds);
    //  // this.miniature.fitBounds(this._bounds);
    //   this.map.on('click', (e) => this.onMapClickEvent(e));
    //   // this.addMarkers();
    // }, 50);
  }

  onMapClickEvent(event: any) {
    let popLocation = event.latlng;
    // console.log('this._container', this._container);
    // console.log('popLocation', popLocation);
    this.mapClick.emit(popLocation);
    // console.log(this.map.getZoom());
  }

  onMarkerClickEvent(event: any) {
    // console.log('onMarkerClickEvent', event);
    // console.log('this._container', this._container);
    this.markerClick.emit(event.sourceTarget.options.title);
  }

  onMarkerMouseoverEvent(event: any) {
    this.onMarkerMouseover.emit(event.sourceTarget.options.title);
    // marker.on('mouseover',function(ev) {
    //   ev.target.openPopup();
    // });
  }

  public addMarker(data: any) {
    return new Promise(resolve => {
      let position = latLng([data.x, data.y]);
      let currentMarker;
      if (
        (data.icon !== undefined && data.icon !== null)
        && (this._icons !== undefined && this._icons !== null)
        && (this._icons[data.icon] !== undefined && this._icons[data.icon] !== null)
      ) {
        currentMarker = marker(position, {icon: icon(this._icons[data.icon]), title: data.id}).on('click', (e) => this.onMarkerClickEvent(e));
      }
      else {
        currentMarker = marker(position, {title: data.id}).on('click', (e) => this.onMarkerClickEvent(e));
      }

      if (this.map) {
        if (this.mapUseCluster) {
          this.markersRef[data.id] = currentMarker.addTo(this.clusterMarkers);
        }
        else {
          this.markersRef[data.id] = currentMarker.addTo(this.map);
        }
        // this.clusterMarkers.addLayer(currentMarker);
      }
      resolve();
    });
  }

  public removeMarker(id: string) {
    return new Promise(resolve => {
      if (this.map && this.markersRef[id] != undefined) {
        this.map.removeLayer(this.markersRef[id]);
        this.clusterMarkers.removeLayer(this.markersRef[id]);
        delete this.markersRef[id];
        resolve();
      }
      else {
        // console.log('Ref not founded', id, this.markersRef);
        resolve();
      }
    });
  }

  public async refreshMarkers() {
    // console.log('refreshMarkers', this.markersRef);
    // tslint:disable-next-line: forin
    for (const id in this.markersRef) {
      await this.removeMarker(id);
    }
    this.markersRef = [];
    await this.addMarkers();
  }

  public panTo(x: number, y: number, zoom: number = null) {
    if (zoom) {
      this.map.setView([x, y], zoom);
    }
    else {
      this.map.setView([x, y], this.map.getZoom());
    }
  }

  public getBounds() {
    return this.map.getBounds();
  }

  public fitBounds(bounds = null) {
    if (bounds) {
      this.map.fitBounds(bounds);
    }
    else {
      this.map.fitBounds(this._bounds);
    }
  }

  addMarkers() {
    return new Promise(resolve => {
      // console.log('marker icons', this._icons);
      this.clusterMarkers = L.markerClusterGroup();
      // tslint:disable-next-line: no-shadowed-variable
      this._markers.forEach((marker) => {
        this.addMarker(marker);
      });
      if (this.map) {
        if (this.mapUseCluster) {
          this.map.addLayer(this.clusterMarkers);
        }
      }
      resolve();
    });
  }

  public print() {
    this._markers.forEach(_marker => {
    let position = latLng([_marker.x, _marker.y]);

    if (
        (_marker.icon !== undefined && _marker.icon !== null)
        && (this._icons !== undefined && this._icons !== null)
        && (this._icons[_marker.icon] !== undefined && this._icons[_marker.icon] !== null)
      ) {
      let currentMarker = marker(position, {icon: icon(this._icons[_marker.icon]), title: _marker.id})
        .bindTooltip("#" + _marker.id,
        {
          permanent: true,
          direction: 'right'
        }
      );
     // currentMarker.addTo(this.miniature);
      }
    });
    this.printer.printMap('a3CssClass', 'printedMap');
  }

  /** Remove map when we have multiple map object */
  ngOnDestroy() {
    // console.log('n7 leaflet map - ngDestroy');
    if (this.map) {
      this.map.remove();
    }
  }

  private loaded(isLoaded: boolean) {
    if (isLoaded) {
      setTimeout(() => {
        this._renderer.addClass(this._elementRef.nativeElement, 'img-loaded');
      }, 500);
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, 'img-loaded');
    }
  }

  private error(isError: boolean) {
    if (isError) {
      setTimeout(() => {
        this._renderer.addClass(this._elementRef.nativeElement, 'img-error');
      }, 500);
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, 'img-error');
    }
  }

}
