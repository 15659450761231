import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    public api: UserService,
    public router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(!this.api.userAuthenticated()){
        return this.api.autoLogin().then(async res => {
          if(res != null && res == true){
            return true;
          }
          else{
            this.api.logout();
            this.router.navigate(['auth']);
            return false;
          }
        });
      }
      else {
        return true;
      }
  }
}