import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { TaskService, TaskModel, TaskHistoryModel } from 'src/app/core/services/tasks.service';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

const defaultItem = {};

@Component({
  selector: 'n7-task-preview',
  templateUrl: './n7-task-preview.component.html',
  styleUrls: [
    './n7-task-preview.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskPreviewComponent implements OnChanges {
  public item: any;
  public itemImage: string;
  public itemImageCounter: number = 0;
  public itemType: string;
  public itemStyle: string = 'default';
  public taskCircleIcons: any;


  public access_token: string;

  _task: any = {};
  _selectionMode = false;
  _selected = false;
  taskMainIcon = '';
  taskMinorIcon = '';

  constructor(
    private translateService: TranslateService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private taskService: TaskService,
    private domSanitizer: DomSanitizer
  ) {
    this.taskCircleIcons = {
      draft: '/assets/storm-pins/Draft/Circle.svg',
      submitted: '/assets/storm-pins/Sent/Circle.svg',
      'closed-submitted': '/assets/storm-pins/Sent/Circle.svg',
      accepted: '/assets/storm-pins/Accepted/Circle.svg',
      'closed-accepted': '/assets/storm-pins/Accepted/Circle.svg',
      'closed-denied': '/assets/storm-pins/Closed/Declined/Circle.svg',
      denied: '/assets/storm-pins/Declined/Circle.svg',
      'in progress': '/assets/storm-pins/Progress/Circle.svg',
      'closed-in progress': '/assets/storm-pins/Progress/Circle.svg',
      completed: '/assets/storm-pins/Completed/Circle.svg',
      'closed-completed': '/assets/storm-pins/Closed/Completed/Circle.svg',
      open: '/assets/storm-pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/storm-pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/storm-pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/storm-pins/Remark/NoActions/Closed/Circle.svg',
      local_repaint: '/assets/storm-pins/Remark/Local/Circle.svg',
      'closed-local_repaint': '/assets/storm-pins/Remark/Local/Closed/Circle.svg',
      total_repaint: '/assets/storm-pins/Remark/Total/Circle.svg',
      'closed-total_repaint': '/assets/storm-pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/storm-pins/Monitored/Circle.svg',
      'closed-monitored': '/assets/storm-pins/Monitored/Circle.svg',
    };
  }

  @Input() set taskItem(val: any) {
    this.item = (val !== undefined && val !== null) ? val : defaultItem;
  }


  @Input() set token(val: string) {
    this.access_token = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set task(val: string) {
    this._task = (val !== undefined && val !== null) ? val : {};
  }

  @Input() set selectionMode(val: boolean) {
    this._selectionMode = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set selected(val: boolean) {
    this._selected = (val !== undefined && val !== null) ? val : false;
  }

  @Output() onClick = new EventEmitter();

  ngOnChanges() {
    // console.log('n7-task-preview', this._task);


      // this.item.lastupdate_text = this.dateToFromNowDaily(this.item['updated-at']);
      this.item.create_text = moment( this.item['created-at'] ).format('DD.MM.YYYY HH:mm');
      this.item.lastupdate_text = moment( this.item['updated-at'] ).format('DD.MM.YYYY HH:mm');

      // Author Initials
      if (this.item.author) {
        let authorSplit = this.item.author.split(' ');
        this.item.author_initials = authorSplit[0].charAt(0).toUpperCase() + authorSplit[1].charAt(0).toUpperCase();
      }
      else {
        this.item.author_initials = '';
      }

      // Last Editor Initials
      if (this.item.last_editor) {
        let lastEditorSplit = this.item.last_editor.split(' ');
        this.item.last_editor_initials = lastEditorSplit[0].charAt(0).toUpperCase() + lastEditorSplit[1].charAt(0).toUpperCase();
      }
      else {
        this.item.last_editor_initials = '';
      }


      if (this.access_token) {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.access_token });
        this.taskService.headers = headers;
        // this.taskService.getRelatedCollection(TaskModel, this._task.id, TaskHistoryModel).pipe(
      //   take(1)
      // ).subscribe((data: any) => {
      //   const taskOpenHistory = _.orderBy(data, ['event_date'], ['desc']);
      //   this.itemImage = null;
      //   this.itemImageCounter = 0;
      //   if (taskOpenHistory[0] && 
      //     taskOpenHistory[0].photos && 
      //     taskOpenHistory[0].photos.data
      //   ) {
      //     if (taskOpenHistory[0].photos.data.detailed_images && 
      //       taskOpenHistory[0].photos.data.detailed_images[0] && 
      //       taskOpenHistory[0].photos.data.detailed_images[0].attributes &&
      //       taskOpenHistory[0].photos.data.detailed_images[0].attributes.base64
      //     ) {
      //       this.itemImageCounter += taskOpenHistory[0].photos.data.detailed_images.length;
      //       let trustUrl = null;
      //       const imageData = taskOpenHistory[0].photos.data.detailed_images[0].attributes.base64;
      //       trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
      //       this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
      //     }

      //     if (taskOpenHistory[0].photos.data.additional_images && 
      //       taskOpenHistory[0].photos.data.additional_images[0] && 
      //       taskOpenHistory[0].photos.data.additional_images[0].attributes &&
      //       taskOpenHistory[0].photos.data.additional_images[0].attributes.base64
      //     ) {
      //       this.itemImageCounter += taskOpenHistory[0].photos.data.additional_images.length;
      //       if (!this.itemImage) {
      //         let trustUrl = null;
      //         const imageData = taskOpenHistory[0].photos.data.additional_images[0].attributes.base64;
      //         trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
      //         this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
      //       }
      //     }
      //   }
      // });

      // console.log('this.item', this.item);
    }

    if (this.item.attributes &&
          this.item.attributes.last_history &&
          this.item.attributes.last_history.attributes &&
          this.item.attributes.last_history.attributes.photos &&
          this.item.attributes.last_history.attributes.photos.data
        ) {
          this.itemImageCounter = 0;
          if (this.item.attributes.last_history.attributes.photos.data.detailed_images &&
            this.item.attributes.last_history.attributes.photos.data.detailed_images[0] &&
            this.item.attributes.last_history.attributes.photos.data.detailed_images[0].attributes &&
            this.item.attributes.last_history.attributes.photos.data.detailed_images[0].attributes.base64
          ) {
            this.itemImageCounter += this.item.attributes.last_history.attributes.photos.data.detailed_images.length;
            let trustUrl = null;
            const imageData = this.item.attributes.last_history.attributes.photos.data.detailed_images[0].attributes.base64;
            trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
            this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
          }

          if (this.item.attributes.last_history.attributes.photos.data.additional_images &&
            this.item.attributes.last_history.attributes.photos.data.additional_images[0] &&
            this.item.attributes.last_history.attributes.photos.data.additional_images[0].attributes &&
            this.item.attributes.last_history.attributes.photos.data.additional_images[0].attributes.base64
          ) {
            this.itemImageCounter += this.item.attributes.last_history.attributes.photos.data.additional_images.length;
            if (!this.itemImage) {
              let trustUrl = null;
              const imageData = this.item.attributes.last_history.attributes.photos.data.additional_images[0].attributes.base64;
              trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
              this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
            }
          }
        }


    this._renderer.removeClass(this._elementRef.nativeElement, 'selected');
    // console.log('this._task.status', this._task.status);
    if (this._task.is_open) {
      switch (this._task.status) {
        case 'submitted':
          this.taskMainIcon = '/assets/storm-pins/Sent/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Sent/Micro.svg';
          break;

        case 'accepted':
          this.taskMainIcon = '/assets/storm-pins/Accepted/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Accepted/Micro.svg';
          break;

        case 'completed':
          this.taskMainIcon = '/assets/storm-pins/Completed/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Completed/Micro.svg';
          break;

        case 'draft':
          this.taskMainIcon = '/assets/storm-pins/Draft/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Draft/Micro.svg';
          break;

        case 'in progress':
          this.taskMainIcon = '/assets/storm-pins/Progress/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Progress/Micro.svg';
          break;

        case 'monitored':
          this.taskMainIcon = '/assets/storm-pins/Monitored/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Monitored/Micro.svg';
          break;

        case 'remarked':
          this.taskMainIcon = '/assets/storm-pins/Remark/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Remark/Micro.svg';
          break;

        case 'denied':
          this.taskMainIcon = '/assets/storm-pins/Declined/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Declined/Micro.svg';
          break;

        default:
          this.taskMainIcon = '/assets/storm-pins/Draft/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Draft/Micro.svg';
      }
    }
    else {
      switch (this._task.status) {
        case 'completed':
          this.taskMainIcon = '/assets/storm-pins/Closed/Completed/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Closed/Completed/Micro.svg';
          break;

        case 'monitored':
          this.taskMainIcon = '/assets/storm-pins/Monitored/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Monitored/Micro.svg';
          break;

        case 'denied':
          this.taskMainIcon = '/assets/storm-pins/Closed/Declined/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Closed/Declined/Micro.svg';
          break;

        default:
          this.taskMainIcon = '/assets/storm-pins/Closed/Declined/Active.svg';
          this.taskMinorIcon = '/assets/storm-pins/Closed/Declined/Micro.svg';
      }
    }

    if (this._selectionMode) {
      // this._renderer.addClass(this._elementRef.nativeElement, 'selected');
    }
  }

  onClickEvent(task: any) {
    this.onClick.emit(task);
  }

  defaultAction() {
    this.onClick.emit(this.item);
  }

  dateToFromNowDaily( customDate ) {
    // get from-now for this date
    var fromNow = moment( customDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( customDate ).calendar( null, {
        // when the date is closer, specify custom values
        lastWeek: '[Last] dddd [at] HH:mm',
        lastDay:  '[Yesterday] [at] HH:mm',
        sameDay:  '[Today] [at] HH:mm',
        nextDay:  '[Tomorrow] [at] HH:mm',
        nextWeek: 'dddd [at] HH:mm',
        // when the date is further away, use from-now functionality             
        sameElse: function () {
            return "[" + fromNow + "]";
        }
    });
  }

}
