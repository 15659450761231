import { Component, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionSheetController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component'
import { N7ModalConfirmComponent } from '../n7-modal-confirm/n7-modal-confirm.component'
import { N7ModalGalleryComponent } from '../n7-modal-gallery/n7-modal-gallery.component';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as uuid from 'uuid';
import { N7ModalDrawComponent } from '../n7-modal-draw/n7-modal-draw.component';
import { RecoveryService } from '../../core/services/recovery.service';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-photo-loader-advanced',
  templateUrl: './n7-photo-loader-advanced.component.html',
  styleUrls: ['./n7-photo-loader-advanced.component.scss'],
})
export class N7PhotoLoaderAdvancedComponent implements OnChanges {
  @ViewChild("file", { read: ElementRef }) private itemFile: ElementRef;

  public index: number;
  public name: string;
  public selfRef: N7PhotoLoaderAdvancedComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemId: string;
  public itemLabel: string;
  public itemHint: string;
  public itemHasError: boolean;
  public itemError: string;

  public itemDetailFiles: string[];
  public itemDetailValues: any[];
  public itemDetailMax: number;

  public itemOverviewFiles: string[];
  public itemOverviewValues: any[];
  public itemOverviewMax: number;
  public itemOverviewHintIsOpen: boolean = false;
  public itemOverviewHintModal: any;

  public itemRatio: any;
  public itemDetailRatio: any;
  public itemOverviewRatio: any;
  public itemType: string = 'base64';
  public itemCanRemove: boolean = true;
  public itemCanDraw: boolean = true;
  public itemGenerateDocumentID: boolean = true;

  public itemRecoveryState: {
    boatId: number,
    projectId: number,
    taskId: number
  } = {
    boatId: null,
    projectId: null,
    taskId: null
  };

  @Input() set id(val: string) {
    this.itemId = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : 'base64';
  }

  @Input() set canRemove(val: boolean) {
    this.itemCanRemove = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canDraw(val: boolean) {
    this.itemCanDraw = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set generateDocumentID(val: boolean) {
    this.itemGenerateDocumentID = (val !== undefined && val !== null) ? val : true;
  }


  @Input() set detailValues(val: string[]) {
    this.itemDetailValues = (val !== undefined && val !== null) ? val.slice(0) : [];
  }

  @Input() set detailMax(val: number) {
    this.itemDetailMax = (val !== undefined && val !== null) ? val : 1;
  }

  @Input() set overviewValues(val: string[]) {
    this.itemOverviewValues = (val !== undefined && val !== null) ? val.slice(0) : [];
  }

  @Input() set overviewMax(val: number) {
    this.itemOverviewMax = (val !== undefined && val !== null) ? val : 1;
  }

  @Input() set recoveryState(val: {
    boatId: number,
    projectId: number,
    taskId: number
  }) {
    this.itemRecoveryState = (val !== undefined && val !== null) ? val : {
      boatId: null,
      projectId: null,
      taskId: null
    };
  }

  @Output() detailValuesChange = new EventEmitter()

  @Output() overviewValuesChange = new EventEmitter()

  @Output() valuesChange = new EventEmitter()

  private unsubscribe$ = new Subject<void>();

  constructor(
    public platform: Platform,
    private camera: Camera,
    private base64: Base64,
    private webview: WebView,
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private domSanitizer: DomSanitizer,
    private screenOrientation: ScreenOrientation,
    private recoveryService: RecoveryService
  ) {
    this.itemRatio = {w: 1, h: 1};
    this.itemDetailFiles = Array(this.itemDetailMax).fill('');
    this.itemOverviewFiles = Array(this.itemOverviewMax).fill('');

    // detect orientation changes
    this.screenOrientation.onChange().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      async () => {
          // console.log("Orientation Changed", this.screenOrientation.type);
          if (this.itemOverviewHintIsOpen) {
            await this.itemOverviewHintModal.dismiss()
          }
      }
    );
  }

  ngOnChanges() {
    this.itemDetailFiles = Array(this.itemDetailMax).fill('');
    this.itemOverviewFiles = Array(this.itemOverviewMax).fill('');

    this.itemRatio = {w: 1, h: 1};
    this.itemDetailRatio = {w: 1, h: 1};
    this.itemOverviewRatio = {w: 2, h: 1};
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  onItemsClear(event) {
    this.itemDetailValues = [];
    this.itemDetailFiles = [];
    this.itemOverviewValues = [];
    this.itemOverviewFiles = [];
    this.itemHasError = false;
    this.itemError = '';
    this.detailValuesChange.emit(this.itemDetailValues);
    this.overviewValuesChange.emit(this.itemOverviewValues);
    this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
  }

  onItemClear(index, type: string, event) {
    this.presentItemClearConfirmModal(index, type, event);
  }

  onItemDraw(index, type: string, event) {
    this.presentDrawModal(index, type, event);
  }

  async presentItemClearConfirmModal(index, type: string, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Remove photo',
        message: 'Do you want to remove the selected image?',
        dismissLabel: 'Cancel',
        confirmLabel: 'Yes, remove'
      },
      cssClass: 'modal-confirm'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          switch (type) {
            case 'detail':
              if ((index > -1) && (index < this.itemDetailMax)) {
                this.itemDetailValues.splice(index, 1);
                this.itemDetailFiles[index] = '';
                this.detailValuesChange.emit(this.itemDetailValues);
                this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
              }
              break;

              case 'overview':
              if ((index > -1) && (index < this.itemOverviewMax)) {
                this.itemOverviewValues.splice(index, 1);
                this.itemOverviewFiles[index] = '';
                this.overviewValuesChange.emit(this.itemOverviewValues);
                this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
              }
              break;
          }
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  async presentOverviewConfirmModal(index, type: string = 'overview') {
    // console.log("Orientation ", this.screenOrientation.type);
    this.itemOverviewHintIsOpen = true;

    this.itemOverviewHintModal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Overview photo',
        message: 'Please rotate your phone in landscape to take an overview photo',
        dismissLabel: 'Cancel',
        confirmLabel: ''
      },
      cssClass: 'modal-confirm'
    });

    this.itemOverviewHintModal.onDidDismiss().then((data) => {
      this.itemOverviewHintIsOpen = false;
      if (
        (this.screenOrientation.ORIENTATIONS.LANDSCAPE == this.screenOrientation.type) ||
        (this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY == this.screenOrientation.type) ||
        (this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY == this.screenOrientation.type)
      ) {
        this.addPhoto(index, type);
      }
    }).catch((error) => {
      this.itemOverviewHintIsOpen = false;
      console.log('Error', error);
    });

    return await this.itemOverviewHintModal.present();
  }

  getBase64(index, type: string, file) {
    // console.log('getBase64', index, type, file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload =  () => {
      // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      let encoded = reader.result.toString();
      const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null

      switch (this.itemType) {
        case 'document':
          let base64FileReplaced = encoded.replace(/(\r\n\t|\n|\r\t)/gm, '');
          let trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
          switch (type) {
            case 'detail':
              this.itemDetailValues[index] = {
                type: 'documents',
                id: document_id,
                attributes: {
                  doc_type: 'detailed_image',
                  base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                }
              }
              break;

            case 'overview':
              this.itemOverviewValues[index] = {
                type: 'documents',
                id: document_id,
                attributes: {
                  doc_type: 'additional_image',
                  base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                }
              }
              break;
          }
          break;

        case 'base64':
        default:
          switch (type) {
            case 'detail':
              this.itemDetailValues[index] = encoded;
              break;

            case 'overview':
              this.itemOverviewValues[index] = encoded;
              break;
          }

      }

      this.detailValuesChange.emit(this.itemDetailValues);
      this.overviewValuesChange.emit(this.itemOverviewValues);
      this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
    },
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  onLoadFile(index, type: string, event) {
    // console.log('onLoadFile', index, type);
    if (event.target.files[0] && event.target.files[0].name) {
      this.getBase64(index, type, event.target.files[0]);
    }
  }

  prepareAddPhoto(index, type: string = 'detail') {
    // console.log('prepareAddPhoto', index, type);
    switch (type) {
      case 'overview':
        if (
          (this.screenOrientation.ORIENTATIONS.LANDSCAPE == this.screenOrientation.type) ||
          (this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY == this.screenOrientation.type) ||
          (this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY == this.screenOrientation.type)
        ) {
          this.addPhoto(index, type);
        }
        else {
          this.presentOverviewConfirmModal(index, type);
        }
        break;

      case 'detail':
      default:
        this.addPhoto(index, type);
        break;
    }
  }

  addPhoto(index, type: string = 'detail') {
    // console.log('addPhoto', index, type);
    this.platform.ready().then(() => {
      if (this.platform.is('ios') || this.platform.is('android')) {
        const options: CameraOptions = {
          sourceType: this.camera.PictureSourceType.CAMERA,
          quality: 90,
          targetWidth: 1200,
          targetHeight: 800,
          destinationType: (this.platform.is('ios')) ? this.camera.DestinationType.DATA_URL : this.camera.DestinationType.FILE_URI,
          encodingType: this.camera.EncodingType.JPEG,
          allowEdit: false,
          correctOrientation: ('overview' !== type),
          saveToPhotoAlbum: false,
          mediaType: 0
        };
        this.takePicture(index, type, options);
      }
      else {
        let file: any = null;
        switch (type) {
          case 'detail':
            file = document.getElementById('item-file-detail_' + this.itemId +  '__' + index);
            file.click();
            break;

          case 'overview':
            file = document.getElementById('item-file-overview_' + this.itemId +  '__' + index);
            file.click();
            break;
        }
      }
    });
  }

  uploadPhoto(index, type: string = 'detail') {
    // console.log('uploadPhoto', index);
    this.platform.ready().then(() => {
      if (this.platform.is('ios') || this.platform.is('android')) {
        // SAVEDPHOTOALBUM
        // PHOTOLIBRARY
        const options: CameraOptions = {
          sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
          quality: 90,
          targetWidth: 1200,
          targetHeight: 800,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          allowEdit: false,
          correctOrientation: ('overview' !== type),
          saveToPhotoAlbum: false,
          mediaType: 0
        };
        this.takePicture(index, type, options);
      }
      else {
        let file: any = null;
        switch (type) {
          case 'detail':
            file = document.getElementById('item-file-detail_' + this.itemId +  '__' + index);
            file.click();
            break;

          case 'overview':
            file = document.getElementById('item-file-overview_' + this.itemId +  '__' + index);
            file.click();
            break;
        }
      }
    });
  }

  takePicture(index, type: string, options: any) {
    // console.log('takePicture');
    if (this.itemRecoveryState && this.itemRecoveryState.boatId && this.itemRecoveryState.projectId && this.itemRecoveryState.taskId) {
      this.recoveryService.saveStateRecovery(JSON.stringify(this.itemRecoveryState)).then(() => {
        this.platform.ready().then(() => {
          if (this.platform.is('cordova')) {
            this.camera.getPicture(options).then((imageData) => {
              // console.log('imageData DATA_URL', imageData);
              if (this.camera.DestinationType.DATA_URL === options.destinationType) {
                let trustUrl = null;
                // console.log('taking picture');
                const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
                switch (this.itemType) {
                  case 'document':
                    trustUrl = this.domSanitizer.bypassSecurityTrustUrl(imageData);
                    switch (type) {
                      case 'detail':
                        this.itemDetailValues[index] = {
                          type: 'documents',
                          id: document_id,
                          attributes: {
                            doc_type: 'detailed_image',
                            base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                          }
                        }
                        break;
    
                      case 'overview':
                        this.itemOverviewValues[index] = {
                          type: 'documents',
                          id: document_id,
                          attributes: {
                            doc_type: 'additional_image',
                            base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                          }
                        }
                        break;
                    }
                    break;
    
                  case 'base64':
                  default:
                    trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
                    switch (type) {
                      case 'detail':
                        this.itemDetailValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                        break;
    
                      case 'overview':
                        this.itemOverviewValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                        break;
                    }
                }
                // console.log('itemDetailValues[index]', this.itemDetailValues[index]);
                this.onSavePicture(type);
              }
              else {
                // console.log('imageData', imageData);
                const imagePath = (this.platform.is('ios')) ? this.webview.convertFileSrc(imageData) : imageData;
                // console.log('imagePath', imagePath);
                this.base64.encodeFile(imagePath).then((base64File: string) => {
                  // console.log('base64File', base64File);
                  let base64FileReplaced = base64File.replace(/(\r\n\t|\n|\r\t)/gm, '');
                  // console.log('base64File', base64File);
    
                  let trustUrl = null;
                  // console.log('taking picture');
                  const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
                  switch (this.itemType) {
                    case 'document':
                      trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
                      switch (type) {
                        case 'detail':
                          this.itemDetailValues[index] = {
                            type: 'documents',
                            id: document_id,
                            attributes: {
                              doc_type: 'detailed_image',
                              base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                            }
                          }
                          break;
    
                        case 'overview':
                          this.itemOverviewValues[index] = {
                            type: 'documents',
                            id: document_id,
                            attributes: {
                              doc_type: 'additional_image',
                              base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                            }
                          }
                          break;
                      }
                      break;
    
                    case 'base64':
                    default:
                      trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + (base64FileReplaced.replace(/^data:(.*,)?/, '')));
                      switch (type) {
                        case 'detail':
                          this.itemDetailValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                          break;
    
                        case 'overview':
                          this.itemOverviewValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                          break;
                      }
                  }
                  // console.log('itemDetailValues[index]', this.itemDetailValues[index]);
                  this.onSavePicture(type);
                }, (err) => {
                  this.error = err;
                  console.log('error', err);
                }).catch(err => {
                  this.error = err;
                  console.log('error', err);
                });
              }
            }, (err) => {
              this.error = err;
              this.recoveryService.removeStateRecovery();
              console.log('error', err);
            }).catch(err => {
              this.error = err;
              console.log('error', err);
            });
          }
        });
      });
    }
    else {
      this.platform.ready().then(() => {
        if (this.platform.is('cordova')) {
          this.camera.getPicture(options).then((imageData) => {
            // console.log('imageData DATA_URL', imageData);
            if (this.camera.DestinationType.DATA_URL === options.destinationType) {
              let trustUrl = null;
              // console.log('taking picture');
              const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
              switch (this.itemType) {
                case 'document':
                  trustUrl = this.domSanitizer.bypassSecurityTrustUrl(imageData);
                  switch (type) {
                    case 'detail':
                      this.itemDetailValues[index] = {
                        type: 'documents',
                        id: document_id,
                        attributes: {
                          doc_type: 'detailed_image',
                          base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                        }
                      }
                      break;
  
                    case 'overview':
                      this.itemOverviewValues[index] = {
                        type: 'documents',
                        id: document_id,
                        attributes: {
                          doc_type: 'additional_image',
                          base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                        }
                      }
                      break;
                  }
                  break;
  
                case 'base64':
                default:
                  trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
                  switch (type) {
                    case 'detail':
                      this.itemDetailValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                      break;
  
                    case 'overview':
                      this.itemOverviewValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                      break;
                  }
              }
              // console.log('itemDetailValues[index]', this.itemDetailValues[index]);
              this.onSavePicture(type);
            }
            else {
              // console.log('imageData', imageData);
              const imagePath = (this.platform.is('ios')) ? this.webview.convertFileSrc(imageData) : imageData;
              // console.log('imagePath', imagePath);
              this.base64.encodeFile(imagePath).then((base64File: string) => {
                // console.log('base64File', base64File);
                let base64FileReplaced = base64File.replace(/(\r\n\t|\n|\r\t)/gm, '');
                // console.log('base64File', base64File);
  
                let trustUrl = null;
                // console.log('taking picture');
                const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
                switch (this.itemType) {
                  case 'document':
                    trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
                    switch (type) {
                      case 'detail':
                        this.itemDetailValues[index] = {
                          type: 'documents',
                          id: document_id,
                          attributes: {
                            doc_type: 'detailed_image',
                            base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                          }
                        }
                        break;
  
                      case 'overview':
                        this.itemOverviewValues[index] = {
                          type: 'documents',
                          id: document_id,
                          attributes: {
                            doc_type: 'additional_image',
                            base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                          }
                        }
                        break;
                    }
                    break;
  
                  case 'base64':
                  default:
                    trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + (base64FileReplaced.replace(/^data:(.*,)?/, '')));
                    switch (type) {
                      case 'detail':
                        this.itemDetailValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                        break;
  
                      case 'overview':
                        this.itemOverviewValues[index] = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
                        break;
                    }
                }
                // console.log('itemDetailValues[index]', this.itemDetailValues[index]);
                this.onSavePicture(type);
              }, (err) => {
                this.error = err;
                console.log('error', err);
              }).catch(err => {
                this.error = err;
                console.log('error', err);
              });
            }
          }, (err) => {
            this.error = err;
            this.recoveryService.removeStateRecovery();
            console.log('error', err);
          }).catch(err => {
            this.error = err;
            console.log('error', err);
          });
        }
      });
    }
  }

  onSavePicture(type: string = 'detail') {
    this.recoveryService.removeStateRecovery();
    // console.log('savePicture', type);
    switch (type) {
      case 'detail':
        this.detailValuesChange.emit(this.itemDetailValues);
        this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
        break;

      case 'overview':
        this.overviewValuesChange.emit(this.itemOverviewValues);
        this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
        break;
    }
  }

  onImageClick(index, type: string) {
    this.presentGalleryModal(index, type, {});
  }

  async presentActionSheet(index, type: string, ev: any) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Actions',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: '',
        handler: () => {
          console.log('action');
          // this.action('delete');
        }
      }]
    });
    await actionSheet.present();
  }

  async presentContextualMenu(index, type: string, ev: any) {
    const drawFormItem = {
      type: 'button',
      title: 'Draw over image',
      prefixIcon: 'create',
      theme: 'popover'
    };
    const deleteFormItem = {
      type: 'button',
      title: 'Delete image',
      prefixIcon: 'trash',
      theme: 'popover-delete'
    };
    let formItems = [];
    if (this.itemCanDraw) {
      formItems.push(drawFormItem)
    }
    if (this.itemCanRemove) {
      formItems.push(deleteFormItem)
    }
    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: '',
        formItems: formItems,
        type: 'modal',
        dismissLabel: ''
      },
      cssClass: 'modal-menu modal-auto-height'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && (null != data.data.buttonClicked)) {
          switch (data.data.buttonClicked) {
            case 0:
              // console.log(data.data.buttonClicked);
              this.presentDrawModal(index, type, event);
              break;

            case 1:
              // console.log(data.data.buttonClicked);
              this.presentItemClearConfirmModal(index, type, event);
              break;

            default:
              console.log(data.data.buttonClicked);
          }
        }
      }

    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  async presentGalleryModal(index, type: string, ev: any) {
    // console.log('TODO', type);
    // TODO visione combinata dei due array.
    const modal = await this.modalController.create({
      component: N7ModalGalleryComponent,
      componentProps: {
        itemType: this.itemType,
        items: ('overview' == type) ? this.itemOverviewValues : this.itemDetailValues,
        index: index
      },
      cssClass: 'modal-fullscreen'
    });

    modal.onDidDismiss().then((data) => {
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  async presentDrawModal(index, type: string, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalDrawComponent,
      componentProps: {
        itemType: this.itemType,
        item: ('overview' == type) ? this.itemOverviewValues[index] : this.itemDetailValues[index],
        // items: ('overview' == type) ? this.itemOverviewValues : this.itemDetailValues,
        // index: index
      },
      cssClass: 'modal-fullscreen'
    });

    modal.onDidDismiss().then((data) => {
      // console.log('data', data);
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          // console.log('image', data.data.image);
          let trustUrl = null;
          const document_id = (this.itemGenerateDocumentID) ? 'image__' + uuid.v4() : null
          switch (this.itemType) {
            case 'document':
              let base64FileReplaced = data.data.image.replace(/(\r\n\t|\n|\r\t)/gm, '');
              let trustUrl = this.domSanitizer.bypassSecurityTrustUrl((base64FileReplaced.replace(/^data:(.*,)?/, '')));
              // trustUrl = this.domSanitizer.bypassSecurityTrustUrl(data.data.image);
              switch (type) {
                case 'detail':
                  if ((index > -1) && (index < this.itemDetailMax)) {
                    console.log('detail', index);
                    this.itemDetailValues[index] = {
                      type: 'documents',
                      id: document_id,
                      attributes: {
                        doc_type: 'detailed_image',
                        base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                      }
                    }

                    // this.itemDetailValues.splice(index, 1);
                    // this.itemDetailFiles[index] = '';
                    this.detailValuesChange.emit(this.itemDetailValues);
                    this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
                  }
                  break;

                  case 'overview':
                  if ((index > -1) && (index < this.itemOverviewMax)) {
                    console.log('overview', index);
                    this.itemOverviewValues[index] = {
                      type: 'documents',
                      id: document_id,
                      attributes: {
                        doc_type: 'additional_image',
                        base64: this.domSanitizer.sanitize(SecurityContext.URL, trustUrl)
                      }
                    }

                    // this.itemOverviewValues.splice(index, 1);
                    // this.itemOverviewFiles[index] = '';
                    this.overviewValuesChange.emit(this.itemOverviewValues);
                    this.valuesChange.emit([this.itemDetailValues, this.itemOverviewValues]);
                  }
                  break;
              }
              break;

          }
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
