import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'n7-modal-status',
  templateUrl: './n7-modal-status.component.html',
  styleUrls: ['./n7-modal-status.component.scss'],
})
export class N7ModalStatusComponent implements OnInit {
  public task: any = null;
  public status: string = '';
  public taskStatuses: any[] = [];
  public taskCircleIcons: any;
  public message = '';

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.status = this.task.status;

    // console.log('task', this.task);
    // console.log('status', this.status);

    this.taskCircleIcons = {
      draft: '/assets/storm-pins/Draft/Circle.svg',
      submitted: '/assets/storm-pins/Sent/Circle.svg',
      accepted: '/assets/storm-pins/Accepted/Circle.svg',
      'closed-denied': '/assets/storm-pins/Closed/Declined/Circle.svg',
      denied: '/assets/storm-pins/Declined/Circle.svg',
      'in progress': '/assets/storm-pins/Progress/Circle.svg',
      completed: '/assets/storm-pins/Completed/Circle.svg',
      'closed-completed': '/assets/storm-pins/Closed/Completed/Circle.svg',
      open: '/assets/storm-pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/storm-pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/storm-pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/storm-pins/Remark/NoActions/Closed/Circle.svg',
      local_repaint: '/assets/storm-pins/Remark/Local/Circle.svg',
      'closed-local_repaint': '/assets/storm-pins/Remark/Local/Closed/Circle.svg',
      total_repaint: '/assets/storm-pins/Remark/Total/Circle.svg',
      'closed-total_repaint': '/assets/storm-pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/storm-pins/Monitored/Circle.svg',
    };

    this.taskStatuses = []
    // Primary
    this.taskStatuses.push({
      key: 'draft',
      label: 'Draft',
      nextKeys: ['draft', 'submitted'],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'submitted',
      label: 'Sent',
      nextKeys: ['submitted', 'accepted', 'denied'],
      previousKeys: ['draft']
    });
    this.taskStatuses.push({
      key: 'accepted',
      label: 'Accepted',
      nextKeys: ['accepted', 'in progress'],
      previousKeys: ['submitted']
    });
    this.taskStatuses.push({
      key: 'denied',
      label: 'Declined',
      nextKeys: ['denied'],
      previousKeys: ['submitted']
    });
    this.taskStatuses.push({
      key: 'in progress',
      label: 'In progress',
      nextKeys: ['in progress', 'completed'],
      previousKeys: ['accepted', 'monitored']
    });
    this.taskStatuses.push({
      key: 'completed',
      label: 'Completed',
      nextKeys: ['completed', 'in progress', 'monitored'],
      previousKeys: ['in progress']
    });
    this.taskStatuses.push({
      key: 'monitored',
      label: 'Monitored',
      nextKeys: ['monitored', 'in progress'],
      previousKeys: ['completed']
    });
    // Remark
    this.taskStatuses.push({
      key: 'open',
      label: 'Open',
      nextKeys: ['open', 'no_actions', 'local_repaint', 'total_repaint'],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'no_actions',
      label: 'No actions',
      nextKeys: ['no_actions', 'closed-no_actions'],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'closed-no_actions',
      label: 'Closed remark',
      nextKeys: [],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'local_repaint',
      label: 'Local repaint',
      nextKeys: ['local_repaint', 'closed-local_repaint'],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'closed-local_repaint',
      label: 'Closed remark',
      nextKeys: [],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'total_repaint',
      label: 'Total repaint',
      nextKeys: ['total_repaint', 'closed-total_repaint'],
      previousKeys: []
    });
    this.taskStatuses.push({
      key: 'closed-total_repaint',
      label: 'Closed remark',
      nextKeys: [],
      previousKeys: []
    });
  }

  ngOnInit() {
    // console.log(this.task);
  }

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'taskId': this.task.id,
      'status': this.status,
      'data': data
    });
  }

  changeTaskStatus() {
    // console.log(this.message);
    // console.log('task ID', this.task.id);
    this.dismiss({
      confirmed: true,
      status : this.status,
      message: this.message,
      task : this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onStatusClick(status) {
    this.status = status.key;
  }

  canBeNext(current, nextCandidate) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, function (o) { return o.key == current; });
    if (indexCurrentStatus < 0) {
      return false;
    }
    return ((indexCurrentStatus) && (_.indexOf(this.taskStatuses[indexCurrentStatus].nextKeys, nextCandidate) != -1)) ? true : false;
  }

  canBePrevious(current, previousCandidate) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, function (o) { return o.key == current; });
    if (indexCurrentStatus < 0) {
      return false;
    }
    return ((indexCurrentStatus) && (_.indexOf(this.taskStatuses[indexCurrentStatus].previousKeys, previousCandidate) != -1)) ? true : false;
  }
}
