import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'tasks'
})
export class TaskModel extends JsonApiModel {
  @Attribute()
  task_type: string;

  @Attribute()
  status: string;

  @Attribute()
  description: string;

  @Attribute()
  boat_id: string;

  @Attribute()
  project_id: string;

  @Attribute()
  section_id: string;

  @Attribute()
  section_name: string;

  @Attribute()
  author_id?: string;

  @Attribute()
  author?: string;

  @Attribute()
  last_editor_id?: number;

  @Attribute()
  last_editor?: string;

  @Attribute()
  last_history?: any;

  @Attribute()
  internal_progressive_number?: number;

  @Attribute()
  bridge_position?: string;

  @Attribute()
  intervent_type_id: string;

  @Attribute()
  intervent_type_name: string;

  @Attribute()
  x_coord: number;

  @Attribute()
  y_coord: number;

  @Attribute()
  number: number;

  @Attribute()
  worked_hours: number;

  @Attribute()
  estimated_hours: number;

  @Attribute()
  is_open: number;

  @Attribute()
  is_private: number;

  @Attribute()
  zone: any;

  @Attribute()
  opener_application_log_id: number;

  @Attribute()
  opener_application_log_name: string;

  @Attribute()
  closer_application_log_id: number;

  @Attribute()
  closer_application_log_name: string;

  @Attribute()
  comments: Array<any>;

  @Attribute()
  'created-at'?: Date;

  @Attribute()
  'updated-at'?: Date;
}

@JsonApiModelConfig({
  type: 'task-intervent-types'
})
export class TaskInterventTypeModel extends JsonApiModel {
  @Attribute()
  intervent_id: string;

  @Attribute()
  name: string;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}

@JsonApiModelConfig({
  type: 'history'
})
export class TaskHistoryModel extends JsonApiModel {
  @Attribute()
  user_id: number;

  @Attribute()
  user_name: string;

  @Attribute()
  original_task_status: string;

  @Attribute()
  task_status: string;

  @Attribute()
  comment_id: number;

  @Attribute()
  comment_body: string;

  @Attribute()
  photos: any[];

  @Attribute()
  comments: any[];

  @Attribute()
  event_date: Date;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    tasks: TaskModel,
    'task-intervent-types': TaskInterventTypeModel
  }
}

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class TaskService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getTaskRemarkStatuses(
    headers?: HttpHeaders
  ) {
    const baseUrl = config.baseUrl || this.JsonApiConfig.baseUrl;
    const apiVersion = config.apiVersion || this.JsonApiConfig.apiVersion;
    const customUrl: string = [baseUrl, apiVersion, 'task-remark-statuses'].filter((x) => x).join('/');
    const url: string = this.buildUrl(TaskModel, null, null, null, customUrl);
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
    .pipe(
      map((res: HttpResponse<object>) => {
        // console.log('HttpResponse', res);
        return res;
      }),
      catchError((res: any) => this.handleError(res))
    );
  }

}