import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { ChartsModule } from 'ng2-charts';

import { N7SecuredImageComponent } from './n7-secured-image/n7-secured-image.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { PophoverComponent } from 'src/app/components/pophover/pophover.component';
import { N7LeafletMapComponent } from './n7-leaflet-map/n7-leaflet-map.component';
import { BoatcardComponent } from './boatcard/boatcard.component';
import { N7TaskListComponent } from './n7-task-list/n7-task-list.component';
import { N7TaskPreviewComponent } from './n7-task-preview/n7-task-preview.component';
import { N7TaskCheckComponent } from './n7-task-check/n7-task-check.component';
import { N7TaskComponent } from './n7-task/n7-task.component';
import { N7TaskModalComponent } from './n7-task-modal/n7-task-modal.component';
import { N7ModalStatusComponent } from './n7-modal-status/n7-modal-status.component';
import { N7ModalZoneComponent } from './n7-modal-zone/n7-modal-zone.component';
import { N7ModalInterventTypeComponent } from './n7-modal-intervent-type/n7-modal-intervent-type.component';
import { N7ModalSuggestionsComponent } from './n7-modal-suggestions/n7-modal-suggestions.component';
import { N7TaskListModalComponent } from './n7-task-list-modal/n7-task-list-modal.component';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { NotifyComponent } from './notify/notify.component';
import { NewuserComponent } from './newuser/newuser.component';
import { N7SelectComponent } from './n7-select/n7-select.component';
import { N7CanvasComponent } from './n7-canvas/n7-canvas.component';
import { N7PreloadImageComponent } from './n7-preload-image/n7-preload-image.component';
import { N7HintPopoverComponent } from './n7-hint-popover/n7-hint-popover.component';
import { N7ReportItemPreviewComponent } from './n7-report-item-preview/n7-report-item-preview.component';
import { N7DocumentPreviewComponent } from './n7-document-preview/n7-document-preview.component';
import { N7LogPreviewComponent } from './n7-log-preview/n7-log-preview.component';
import { N7MultipleItemsComponent } from './n7-multiple-items/n7-multiple-items.component';
import { N7SimpleItemComponent } from './n7-simple-item/n7-simple-item.component';
import { N7SectionUnderApplicationItemComponent } from './n7-section-under-application-item/n7-section-under-application-item.component';
import { N7ItemEnvLogComponent } from './n7-item-env-log/n7-item-env-log.component';
import { N7ItemZoneLogComponent } from './n7-item-zone-log/n7-item-zone-log.component';
import { N7ModalFormComponent } from './n7-modal-form/n7-modal-form.component';
import { N7ModalConfirmComponent } from './n7-modal-confirm/n7-modal-confirm.component';
import { N7ModalGalleryComponent } from './n7-modal-gallery/n7-modal-gallery.component';
import { N7ModalDrawComponent } from './n7-modal-draw/n7-modal-draw.component';
import { N7ButtonComponent } from './n7-button/n7-button.component';
import { N7TextInputComponent } from './n7-text-input/n7-text-input.component';
import { N7TextPickerComponent } from './n7-text-picker/n7-text-picker.component';
import { N7DropDownComponent } from './n7-drop-down/n7-drop-down.component';
import { N7CheckboxListComponent } from './n7-checkbox-list/n7-checkbox-list.component';
import { N7FileUploadComponent } from './n7-file-upload/n7-file-upload.component';
import { N7DatetimePickerComponent } from './n7-datetime-picker/n7-datetime-picker.component';
import { N7PhotoLoaderComponent } from './n7-photo-loader/n7-photo-loader.component';
import { N7PhotoLoaderAdvancedComponent } from './n7-photo-loader-advanced/n7-photo-loader-advanced.component';
import { N7TextareaSuggestionsComponent } from './n7-textarea-suggestions/n7-textarea-suggestions.component';
import { N7DataInputComponent } from './n7-data-input/n7-data-input.component';
import { N7GenerateReportComponent } from './n7-generate-report/n7-generate-report.component';
import { N7ArchivedPreviewComponent } from './n7-archived-preview/n7-archived-preview.component';

import { PrintTaskMapComponent } from './print-task-map/print-task-map.component';
import { PrintMapComponent } from './print-map/print-map.component';
import { N7TapOnMapComponent } from './n7-tap-on-map/n7-tap-on-map.component';
import { N7DrawOnPhotoComponent } from './n7-draw-on-photo/n7-draw-on-photo.component';
import { ProjecthistoryComponent } from './projecthistory/projecthistory.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { StTimeReportComponent } from './st-time-report/st-time-report.component';
import { StCardShipyardComponent } from './st-card-shipyard/st-card-shipyard.component';
import { StResourceProfileComponent } from './st-resource-profile/st-resource-profile.component';
import { StFilterCheckboxListComponent } from './st-filter-checkbox-list/st-filter-checkbox-list.component';
import { StModalResourceDetailComponent } from './st-modal-resource-detail/st-modal-resource-detail.component';
import { StModalImportResourcesComponent } from './st-modal-import-resources/st-modal-import-resources.component';
import { StTimeReportAnomaliesComponent } from './st-time-report-anomalies/st-time-report-anomalies.component';
import { StFormCheckboxComponent } from './st-form-checkbox/st-form-checkbox.component';
import { StPercentageRingComponent } from './st-percentage-ring/st-percentage-ring.component';
import { StChartPieComponent } from './st-chart-pie/st-chart-pie.component';
import { StWorkStepComponent } from './st-work-step/st-work-step.component';
import { StPopoverActionsComponent } from './st-popover-actions/st-popover-actions.component';
import { StSecuredCodeImageComponent } from './st-secured-code-image/st-secured-code-image.component';
import { StModalRegisterInvoiceComponent } from './st-modal-register-invoice/st-modal-register-invoice.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { StDaterangePickerComponent } from './st-daterange-picker/st-daterange-picker.component';

const components = [
  N7SecuredImageComponent,
  AppHeaderComponent,
  PophoverComponent,
  N7LeafletMapComponent,
  BoatcardComponent,
  N7TaskListComponent,
  N7TaskPreviewComponent,
  N7TaskCheckComponent,
  N7TaskComponent,
  N7TaskModalComponent,
  N7ModalStatusComponent,
  N7ModalZoneComponent,
  N7ModalInterventTypeComponent,
  N7ModalSuggestionsComponent,
  N7TaskListModalComponent,
  AutocompleteComponent,
  NotifyComponent,
  NewuserComponent,
  ProjecthistoryComponent,
  N7HintPopoverComponent,
  N7TapOnMapComponent,
  N7DrawOnPhotoComponent,
  N7SelectComponent,
  N7CanvasComponent,
  N7PreloadImageComponent,
  N7ReportItemPreviewComponent,
  N7DocumentPreviewComponent,
  N7LogPreviewComponent,
  N7MultipleItemsComponent,
  N7SimpleItemComponent,
  N7SectionUnderApplicationItemComponent,
  PrintMapComponent,
  PrintTaskMapComponent,
  N7ModalFormComponent,
  N7ModalConfirmComponent,
  N7ModalGalleryComponent,
  N7ModalDrawComponent,
  N7ButtonComponent,
  N7TextInputComponent,
  N7TextPickerComponent,
  N7DropDownComponent,
  N7CheckboxListComponent,
  N7FileUploadComponent,
  N7DatetimePickerComponent,
  N7PhotoLoaderComponent,
  N7PhotoLoaderAdvancedComponent,
  N7TextareaSuggestionsComponent,
  N7DataInputComponent,
  N7ItemEnvLogComponent,
  N7ItemZoneLogComponent,
  N7GenerateReportComponent,
  N7ArchivedPreviewComponent,
  StCardShipyardComponent,
  StFilterCheckboxListComponent,
  StResourceProfileComponent,
  StModalImportResourcesComponent,
  StModalResourceDetailComponent,
  StTimeReportComponent,
  StTimeReportAnomaliesComponent,
  StFormCheckboxComponent,
  StPercentageRingComponent,
  StChartPieComponent,
  StWorkStepComponent,
  StPopoverActionsComponent,
  StSecuredCodeImageComponent,
  StModalRegisterInvoiceComponent,
  StDaterangePickerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    // IonicModule.forRoot(),
    IonicModule,
    TranslateModule,
    FormsModule,
    AutosizeModule,
    PipesModule,
    NgxDatatableModule,
    NgxDaterangepickerMd.forRoot(),
    ChartsModule
  ],
  declarations: components,
  exports: components,
  entryComponents: [
    PophoverComponent,
    N7HintPopoverComponent,
    N7TapOnMapComponent,
    N7DrawOnPhotoComponent,
    N7SimpleItemComponent,
    N7SectionUnderApplicationItemComponent,
    N7ModalStatusComponent,
    N7ModalZoneComponent,
    N7ModalInterventTypeComponent,
    N7ModalSuggestionsComponent,
    N7TaskListModalComponent,
    N7ModalFormComponent,
    N7ModalConfirmComponent,
    N7ModalGalleryComponent,
    N7ModalDrawComponent,
    N7ButtonComponent,
    N7TextInputComponent,
    N7TextPickerComponent,
    N7DropDownComponent,
    N7CheckboxListComponent,
    N7FileUploadComponent,
    N7DatetimePickerComponent,
    N7PhotoLoaderComponent,
    N7PhotoLoaderAdvancedComponent,
    N7TextareaSuggestionsComponent,
    N7DataInputComponent,
    N7ItemEnvLogComponent,
    N7ItemZoneLogComponent,
    N7TaskComponent,
    N7TaskModalComponent,
    StCardShipyardComponent,
    StFilterCheckboxListComponent,
    StResourceProfileComponent,
    StModalImportResourcesComponent,
    StModalResourceDetailComponent,
    StTimeReportComponent,
    StTimeReportAnomaliesComponent,
    StFormCheckboxComponent,
    StPercentageRingComponent,
    StChartPieComponent,
    StWorkStepComponent,
    StPopoverActionsComponent,
    StSecuredCodeImageComponent,
    StModalRegisterInvoiceComponent,
    StDaterangePickerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }