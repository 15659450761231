import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, switchMap, catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as UserActions from './user.actions';
import { Oauth2Service } from '../../core/services/oauth2.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions: Actions,
    private oauth2: Oauth2Service,
  ) {}

  @Effect()
  loginUser = this.actions.pipe(
    ofType<UserActions.LoginUserBegin>(UserActions.ActionTypes.LoginUserBegin),
    switchMap((action) => {
      return this.oauth2.token(
        action.payload.data.username, action.payload.data.password, action.payload.data.scope
      ).pipe(
        map(data => new UserActions.LoginUserSuccess({ data: data })),
        catchError(error =>
          of(new UserActions.LoginUserFailure({ error: error }))
        )
      );
    })
  );

  @Effect()
  refreshToken = this.actions.pipe(
    ofType<UserActions.RefreshTokenBegin>(UserActions.ActionTypes.RefreshTokenBegin),
    switchMap((action) => {
      return this.oauth2.tokenRefresh(
        action.payload
      ).pipe(
        map(data => new UserActions.RefreshTokenSuccess({ data: data })),
        catchError(error =>
          of(new UserActions.RefreshTokenFailure({ error: error }))
        )
      );
    })
  );

  @Effect()
  loadUser = this.actions.pipe(
    ofType<UserActions.LoginUserSuccess>(UserActions.ActionTypes.LoginUserSuccess),
    switchMap((action) => {
      console.log('loadUser', action);

      return this.oauth2.user(
        action.payload.data.access_token
      ).pipe(
        map(data => new UserActions.LoadUserSuccess({ data: data })),
        catchError(error =>
          of(new UserActions.LoadUserFailure({ error: error }))
        )
      );
    })
  );

  @Effect()
  logoutUser = this.actions.pipe(
    ofType<UserActions.LogoutUserBegin>(UserActions.ActionTypes.LogoutUserBegin),
    switchMap((action) => {
      return this.oauth2.logout(
        action.payload.access_token
      ).pipe(
        map(data => new UserActions.LogoutUserSuccess({ data: data })),
        catchError(error =>
          of(new UserActions.LogoutUserFailure({ error: error }))
        )
      );
    })
  );
}
