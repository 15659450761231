import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'anomalies'
})
export class AnomalyModel extends JsonApiModel {
  @Attribute()
  is_read: number;

  @Attribute()
  resource_full_name: string;

  @Attribute()
  resource_profile_image_id: string;

  @Attribute()
  created_at: string;

  @Attribute()
  activity_phase: string;

  @Attribute()
  zone_description: string;

  @Attribute()
  app_stamping: number;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    Anomaly: AnomalyModel
  }
}
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class AnomalyService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getAnomalies() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        this.getCollection(AnomalyModel)
          .pipe(take(1))
          .subscribe(async remoteAnomalies => {
            const anomalies = [];
            remoteAnomalies.forEach(remoteAnomaly => {
              const Anomaly = {
                id: remoteAnomaly.id,
                free_surface: (remoteAnomaly.free_surface) ? remoteAnomaly.free_surface : null,
                notes: (remoteAnomaly.notes) ? remoteAnomaly.notes : null,
                week: (remoteAnomaly.week) ? remoteAnomaly.week : null,
                year: (remoteAnomaly.year) ? remoteAnomaly.year : null,
                zone_description: (remoteAnomaly.zone_description) ? remoteAnomaly.zone_description : null,
              };
              anomalies.push(Anomaly);
            });
            resolve( anomalies );
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  public getAnomaly(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        this.getEntity(AnomalyModel, id)
          .pipe(take(1))
          .subscribe(async remoteAnomaly => {
            const anomaly = {
              id: remoteAnomaly.id,
              free_surface: (remoteAnomaly.free_surface) ? remoteAnomaly.free_surface : null,
              notes: (remoteAnomaly.notes) ? remoteAnomaly.notes : null,
              week: (remoteAnomaly.week) ? remoteAnomaly.week : null,
              year: (remoteAnomaly.year) ? remoteAnomaly.year : null,
              zone_description: (remoteAnomaly.zone_description) ? remoteAnomaly.zone_description : null,
            };
            resolve(anomaly);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  // bulk-mark-as-read
  public bulkMarkAsRead(
    anomalyIds: string[],
    headers?: HttpHeaders
  ) {
    try {
      const stringAnomalyIds = anomalyIds.join(', ');
      const url: string = this.buildUrl(AnomalyModel, null, null, null, null) + '/bulk-mark-as-read';
      // console.log('url', url);
      const requestOptions: object = this.buildRequestOptions({ headers });
      const body: any = {
        anomalies: stringAnomalyIds
      };

      return this.http.post(url, body, requestOptions)
        .pipe(
          map((res: HttpResponse<object>) => {
            // console.log('HttpResponse', res);
            return res;
          }),
          catchError((res: any) => this.handleError(res))
        );
    } catch (error) {
    }
  }
}