import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Utilities } from 'src/common/utilities.class';
import { SiteModel, SiteService } from './sites.service';
import { UserService } from './user.service';

/**
 * Shipyard interface
 */
export interface Shipyard {
  id?: string,
  code?: string,
  name: string,
  location?: string,
  city?: string,
  image?: string,
}

/**
 * ShipyardService
 */
@Injectable({
  providedIn: 'root'
})
export class ShipyardService {
  private shipyards = [
    {
      id: '1',
      image: '1',
      city: 'Livorno',
      name: 'Benetti'
    },
    {
      id: '2',
      image: '52',
      city: 'Trieste',
      name: 'Cartubi'
    },
    {
      id: '3',
      image: '53',
      city: 'Carrara',
      name: 'Nca'
    },
    {
      id: '4',
      image: '60',
      city: 'La Spezia',
      name: 'Baglietto'
    }
  ];
  private accessToken: string;

  constructor(
    private userService: UserService,
    private siteService: SiteService
  ) {
  }

  async authorize() {
    return new Promise((resolve, reject) => {
      try {
        this.userService.getToken().pipe(
          take(1)
        ).subscribe(accessToken => {
          if (!accessToken) {
            // this.router.navigateByUrl('auth');
          } else {
            this.accessToken = accessToken;
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
            this.siteService.headers = headers;
          }
          resolve(true);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create a new shipyard
   * 
   * @returns 
   */
  createShipyard(value: Shipyard) {
    return new Promise<Shipyard>(async (resolve, reject) => {
      try {
        await this.authorize();
        const data = {
          code: value.code,
          name: value.name,
          location: value.city,
        };

        const site = this.siteService.createEntity(SiteModel, data);
        this.siteService.addEntity(site).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          console.log('resourceData', resourceData);
          resolve(resourceData.data);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Retrieve a shipyard
   * 
   * @returns 
   */
  getShipyard(shipyardId: string) {
    return new Promise<Shipyard>(async (resolve, reject) => {
      try {
        const shipyards = [];
        await this.authorize();
        this.siteService.getEntity(SiteModel, shipyardId).pipe(
          take(1)
        ).subscribe((site) => {
          // console.log('site', site);
          const shipyard = {
            id: site.id,
            code: site.code,
            image: site.meta.image,
            city: site.location,
            name: site.name
          };
          resolve(shipyard);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Retrieve the shipyards
   * 
   * @returns 
   */
  getShipyards() {
    return new Promise<Shipyard[]>(async (resolve, reject) => {
      try {
        const shipyards = [];
        await this.authorize();
        this.siteService.getCollection(SiteModel).pipe(
          take(1)
        ).subscribe((sites) => {
          sites.forEach(site => {
            const shipyard = {
              id: site.id,
              image: site.meta.image,
              city: site.location,
              name: site.name
            };
            shipyards.push(shipyard);
          });
          resolve(shipyards);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Update a shipyard
   * 
   * @returns 
   */
  updateShipyard(shipyardId: string, value: Shipyard) {
    return new Promise<Shipyard>(async (resolve, reject) => {
      try {
        await this.authorize();
        const data = {
          code: value.code,
          name: value.name,
          location: value.city,
        };

        const site = this.siteService.createEntity(SiteModel, data);
        this.siteService.updateEntity(shipyardId, site).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          // console.log('resourceData', resourceData);
          resolve(resourceData.data);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Delete a shipyard
   * 
   * @returns 
   */
  deleteShipyard(shipyardId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.authorize();
        this.siteService.deleteEntity(SiteModel, shipyardId).pipe(
          take(1)
        ).subscribe((response) => {
          // console.log('response', response);
          resolve(true);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

}
