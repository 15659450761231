import { Storage } from '@ionic/storage';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, take } from 'rxjs/operators';
import { User, Credentials } from "../../state/user/user.model";
import * as UserActions from "../../state/user/user.actions";
import { AppState, getUserState, getUserToken, getUserData, getUserLoading, getShowedHints } from "../../state/app.reducer";
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class UserService {
  public user: Observable<User>;
  public loaded: boolean = false;

  constructor(
    private store: Store<AppState>,
    private storage: Storage,
    protected http: HttpClient
  ) {

  }

  getLoading() {
    return this.store.select(getUserLoading);
  }

  async login(data: Credentials) {
    this.store.dispatch(new UserActions.LoginUserBegin({ data: data }));
  }
  // per i modelli usare la seguente nomenclatura per il nome della chiave es: clienti clienti_23, boat boat_1 boat_image_1
  async setValue(variable: string, value: any) {
    const version = await this.version();
    // console.log('version', version);
    this.storage.set(version + '_' + variable, value);
  }

  async getValue(variable) {
    const version = await this.version();
    // console.log('version', version);
    return this.storage.get(version + '_' + variable);
  }

  async removeValue(variable) {
    const version = await this.version();
    // console.log('version', version);
    return this.storage.remove(version + '_' + variable);
  }

  resetStorage() {
    this.storage.clear();
  }

  async version() {
    return new Promise<string>((resolve, reject) => {
      try {
        this.storage.get('VERSIONE').then((value) => {
          resolve(value);
        });
      } catch (error) {
        reject('');
      }
    });
  }

  async getBackendVersion(httpHeaders?: HttpHeaders) {
    return new Promise<string>((resolve, reject) => {
      try {
        // console.log('getBackendVersion');
        const url = environment.api.base_url + environment.api.version + '/version';
        const requestOptions: object = Object.assign({
          headers: httpHeaders
        });
        this.http.get(url, requestOptions).pipe(
          take(1)
        ).subscribe((data: any) => {
          // console.log('Version', data.data.id);
          resolve(data.data.id);
        });
      } catch (error) {
        reject('');
      }
    })
  }

  async checkStorage(token) {
    // cerco nello storage la versione corrente
    this.getValue('VERSIONE').then(async (value) => {
      // console.log('current version value', value)
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      const version = await this.getBackendVersion(headers);
      // console.log('new version value', version)

      // confronto la versione con quella del server
      // se differisce allora devo salvarla
      if (value !== version) {
        // console.log('setting version value', version)
        this.storage.clear();
        this.storage.set('VERSIONE', version);
      }
    });
  }

  getUserState() {
    return this.store.select(getUserState);
  }

  getUser() {
    return this.store.select(getUserData);
  }

  getToken() {
    return this.store.select(getUserToken);
  }

  async addShowedHint(hint: string) {
    this.store.dispatch(new UserActions.ShowedHint({ hint: hint }));
  }

  getShowedHints() {
    return this.store.select(getShowedHints);
  }

  async logout() {
    this.getToken().pipe(
      take(1)
    ).subscribe(access_token => {
      if (access_token) {
        this.store.dispatch(new UserActions.LogoutUserBegin({ access_token: access_token }));
      }
      else {
        this.store.dispatch(new UserActions.LogoutUserSuccess(null));
      }
    });
  }

  userAuthenticated() {
    return new Promise<boolean>(resolve => {
      this.getUserState().pipe(
        filter(status => !status.loading),
        take(1)
      ).subscribe((status) => {
        if ((!status.access_token) && (status.error)) {
          // console.log('userAuthenticated FALSE');
          resolve(false);
        }
        else {
          // console.log('userAuthenticated TRUE');
          resolve(true);
        }
      });
    });
  }

  autoLogin() {
    return new Promise(resolve => {
      // console.log('autoLogin');
      this.getUserState().pipe(
        filter(status => !status.loading),
        take(1)
      ).subscribe((status) => {
        if ((status.access_token != null) && (status.access_token != '')) {
          if ((status.refresh_token != null) && (status.refresh_token != '')) {
            this.store.dispatch(new UserActions.RefreshTokenBegin(status.refresh_token));
            resolve(true);
          }
          else {
            resolve(null);
          }
        }
        else {
          resolve(null);
        }
      });
    });
  }

  refreshLogin() {
    return new Promise(resolve => {
      // console.log('refreshLogin');
      this.getUserState().pipe(
        filter(status => !status.loading),
        take(1)
      ).subscribe((status) => {
        if ((status.access_token != null) && (status.access_token != '')) {
          if ((status.refresh_token != null) && (status.refresh_token != '')) {
            this.store.dispatch(new UserActions.RefreshTokenBegin(status.refresh_token));
            resolve(true);
          }
          else {
            resolve(null);
          }
        }
        else {
          resolve(null);
        }
      });
    });
  }

}
