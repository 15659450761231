import { Attributes } from './../../state/storm/storm.model';
import { BoatModel } from './boats.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@JsonApiModelConfig({
  type: 'projects'
})
export class ProjectModel extends JsonApiModel {
  @Attribute()
  name: string;

  @Attribute()
  boat_id: number;

  @Attribute()
  project_id: number;

  @Attribute()
  status: string;

  @Attribute()
  boat: BoatModel;

  @Attribute()
  project_type: string;


  @Attribute()
  location: any;

  @Attribute()
  imported: number;

  @Attribute()
  project_progress: number;

  @Attribute()
  management_active: boolean;

  @Attribute()
  // tslint:disable-next-line: variable-name
  start_date: Date;

  @Attribute()
  // tslint:disable-next-line: variable-name
  end_date: Date;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;

  @Attribute()
  gdrive_dir_id: string;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    projects: ProjectModel
  }
}

// template: string,
// date_start: string,
// date_end: string,
// temp_min_threshold: string,
// dp_min_threshold: string,
// hum_min_threshold: string,

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class ProjectService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getUsers<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, null, customUrl) + '/relationships/users';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          //  const data = this.extractCollectionData(res, 'users');
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getStatuses<T extends JsonApiModel>(modelType: ModelType<T>, headers?: HttpHeaders) {
    const url: string = this.buildUrl(modelType, null, null, null, null) + '/statuses';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public setupUsers<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    users,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, null, customUrl) + '/relationships/users';

    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    const typeName = 'boats';
    let httpCall: Observable<HttpResponse<object>>;
    const body: any = {
      data: users
    };
    httpCall = this.http.patch<object>(url, body, requestOptions) as Observable<HttpResponse<object>>;
    return httpCall
      .pipe(
        map((res) => {
          return res.status;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getArchived<T extends JsonApiModel>(modelType: ModelType<T>, boatId, headers?: HttpHeaders) {
    const url: string = this.buildUrl(modelType, null, null, null, null);
    const params = {
      filter: {
        status: 'closed',
        boat_id: boatId,
      },
      // sort: '-end_date'
    };
    const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: any) => { // per ora any ma andrebbe creato apposito modello estendendo
          //return res.body.data;
          return this.extractCollectionData(res, modelType);
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public archive<T extends JsonApiModel>(
    modelType: ModelType<T>,
    projectId: string,
    isForced: boolean,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const typeName: string = 'projects';
    const url: string = this.buildUrl(modelType, params, projectId, undefined, customUrl) + '/close';
    const requestOptions: object = this.buildRequestOptions({ headers });
    const body: any = {
      data: {
        type: typeName,
        attributes: {
          force: (isForced) ? 1 : 0
        }
      }
    };

    return this.http.post(url, body, requestOptions).pipe(
      map((res: HttpResponse<object>) => {
        console.log('HttpResponse', res);
        return res;
      }),
      catchError((res: any) => this.handleError(res))
    );
  }

  public getAllArchived<T extends JsonApiModel>(modelType: ModelType<T>, params?: any, headers?: HttpHeaders) {
    const url: string = this.buildUrl(modelType, null, null, null, null) + '/closed';
    const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: any) => { // per ora any ma andrebbe creato apposito modello estendendo
          return this.extractCollectionData(res, modelType);
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getReportList(
    project: string,
    page?: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/reports-list';
    // console.log('url', url);
    const params = (page) ? { page: page } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    const typeName: string = 'documents';
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getEnvMeasurementsDatasources(
    project: string,
    page?: any,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/env-measurements-datasources';
    const params = (page) ? { page: page } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    const typeName: string = 'data_source';
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getEnvMeasurementsLogs(
    project: string,
    page?: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/env-measurements-logs';
    // console.log('url', url);
    const params = (page) ? { page: page } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    const typeName: string = 'documents';
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public uploadEnvMeasurementLog(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/upload-env-measurement-log';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers });
    const typeName: string = 'documents';
    const body: any = {
      data: {
        type: typeName,
        attributes: data
      }
    }
    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public deleteEnvMeasurementLog(
    project: string,
    documentId: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/env-log-delete';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers });
    const typeName: string = 'documents';
    const body: any = {
      document_id: documentId
    }
    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public generateEnvironmentalReport(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-environmental-report';
    // console.log('url', url);
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public createZones(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/bulk-create-zones';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public deleteZones(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    let body = data;
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/bulk-delete-zones';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getApplicationLogs(
    project: string,
    page?: any,
    headers?: HttpHeaders
  ) {
    // TODO
    // console.log('getApplicationLogs TODO');
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/application_logs';
    // console.log('url', url);
    const params = (page) ? { page: page } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    const typeName: string = 'documents';
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getApplicationLog(
    projectId: string,
    documentId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/app-log-structure/' + documentId;
    // console.log('url', url);
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getApplicationLogNextIPN(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/app-log-next-id';
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );

  }

  public setApplicationLog(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/app-log-structure';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public setRelatedProduct(
    projectId: string,
    productId: string,
    headers?: HttpHeaders
  ) {
    let body = {
      data: {
        type: 'project-products',
        attributes: {
          project_id: projectId,
          product_id: productId
        }
      }
    };
    // console.log('body', body);
    const baseUrl = config.baseUrl || this.JsonApiConfig.baseUrl;
    const apiVersion = config.apiVersion || this.JsonApiConfig.apiVersion;
    const customUrl: string = [baseUrl, apiVersion, 'project-products'].filter((x) => x).join('/');
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, customUrl);
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getTaskStatistics(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/tasks-statistics';
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );

  }

  public generateApplicationReport(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-application-log-report';
    // console.log('url', url);
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public generateApplicationReportQueued(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-application-log-report-queued';
    // console.log('url', url);
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public generateSurfaceReportQueued(
    project: string,
    data: any,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).
    let body = data;
    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-surface-availabilities-report-queued';
    // console.log('url', url);
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getBudgets(
    project: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/get-budgets';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public setBudgets(
    project: string,
    totalAmount: number,
    resources: number,
    materialsAndConsumables: number,
    subcontracting: number,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/set-budgets';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers });
    const body: any = {
      total_amount: totalAmount,
      resources: resources,
      materials_and_consumables: materialsAndConsumables,
      subcontracting: subcontracting,
    };

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getDashboardCosts(
    project: string,
    filter?: any,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/costs-dashboard';
    // console.log('url', url);
    const params = (filter) ? { filter: filter } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getDashboardZones(
    project: string,
    filter?: any,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/zones-dashboard';
    // console.log('url', url);
    const params = (filter) ? { filter: filter } : {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public registerInvoice(
    projectId: string,
    companyId: string,
    amount: string,
    zoneId: string,
    headers?: HttpHeaders
  ) {
    let body = {
      company_id: companyId,
      amount: amount,
      zone_id: zoneId
    };
    // console.log('body', body);
    const baseUrl = config.baseUrl || this.JsonApiConfig.baseUrl;
    const apiVersion = config.apiVersion || this.JsonApiConfig.apiVersion;
    const customUrl: string = [baseUrl, apiVersion, 'projects', projectId, 'register-invoice'].filter((x) => x).join('/');
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, customUrl);
    // console.log('url', url);

    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getSurfaceAvailabilities(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/surface-availabilities';
    const requestOptions: object = this.buildRequestOptions({ headers });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getTimeReports(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/time-reports';
    const requestOptions: object = this.buildRequestOptions({ headers });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getTimeAnomalies(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/anomalies';
    const requestOptions: object = this.buildRequestOptions({ headers });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getUnreadAnomalies(
    projectId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/unread-anomalies';
    const requestOptions: object = this.buildRequestOptions({ headers });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getTimereportCSV(
    projectId: string,
    filename: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ProjectModel, null, projectId, null, null) + '/download-timereport-csv';
    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'text', observe: 'response' });
    let body = {
      'filename': filename
    };

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );

  }
}
