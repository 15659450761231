import { UserService } from '../../core/services/user.service';
import { Component, Input, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'st-secured-code-image',
  templateUrl: './st-secured-code-image.component.html',
  styleUrls: [
    './st-secured-code-image.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class StSecuredCodeImageComponent implements OnChanges {
  private access_token: string;
  private resource_id: string;
  private image_ratio: { w: number, h: number };
  private image_type: string;
  private image_overlay: string;
  public image_class: boolean;
  public image_asBackground: boolean;
  private zoom: boolean;
  public src: string;
  private customUrl: string;
  private src$ = new BehaviorSubject(this.src);
  public isLoaded = false;
  public toStore = false;

  public imageStyle = { 'height': '100vh', 'background-color': 'red' };

  dataUrl$ = this.src$.pipe(
    switchMap(
      url => this.loadImage(url)
    )
  );

  constructor(
    private httpClient: HttpClient,
    private userservice: UserService,
    private domSanitizer: DomSanitizer,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
    this.image_type = '';
  }

  @Input() set token(val: string) {
    this.access_token = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set resource(val: string) {
    this.resource_id = (val !== undefined && val !== null) ? val : '';

  }
  @Input() set url(val: string) {
    this.customUrl = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set class(val: boolean) {
    this.image_class = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set ratio(ratio: { w: number, h: number }) {
    this.image_ratio = ratio || { w: 1, h: 1 };
  }

  @Input() set asBackground(val: boolean) {
    this.image_asBackground = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set type(val: string) {
    this.image_type = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set overlay(val: string) {
    this.image_overlay = (val !== undefined && val !== null) ? val : '';
  }

  @Output() OnClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.image_ratio = { w: 1, h: 1 };
  }

  ngOnChanges() {
    const ratio_height = (this.image_ratio.h / this.image_ratio.w * 100) + '%';
    // Conserve aspect ratio (see: https://stackoverflow.com/a/10441480/1116959)
    this._renderer.setStyle(this._elementRef.nativeElement, 'padding', '0px 0px ' + ratio_height + ' 0px');
    this.update();

    if (this.resource_id !== undefined && this.resource_id !== null && this.resource_id !== '') {
      /*
        vado a vedere se esiste la chiave nello storage boat_image_this.resource_id
        se lo trovo valorizzo src = al risultato dello storage.
      */
      const self = this;
      this.userservice.getValue('imageId' + this.image_type + this.resource_id).then((value) => {
        if (value != null) {
          // console.log('IMG loaded from store');
          this.loaded(true);
          this.src$.complete();
          this.src = value;
          return;
        } else {
          // console.log('IMG to store, loading...');
          this.toStore = true;
          if (self.image_type !== undefined && self.image_type !== null && self.image_type !== '') {
            // tslint:disable-next-line: max-line-length
            // self.src = environment.api.base_url.replace(/\/+$/, "") + '/' + environment.api.version + '/documents/' + self.resource_id + '/show/' + self.image_type;
            self.src = environment.api.base_url.replace(/\/+$/, "") + '/' + environment.api.version + '/resources/' + self.resource_id + '/download-badge-code-img/' + self.image_type;
          } else {
            // self.src = environment.api.base_url.replace(/\/+$/, "") + '/' + environment.api.version + '/documents/' + self.resource_id + '/show';
            self.src = environment.api.base_url.replace(/\/+$/, "") + '/' + environment.api.version + '/resources/' + self.resource_id + '/download-badge-code-img';
            // console.log('self.src', self.src);
          }
          self.src$.next(self.src);
        }
      });
    }
  }

  registerImage(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // console.log('IMG to store, saving...');
      this.userservice.setValue('imageId' + this.image_type + this.resource_id, reader.result);
      setTimeout(() => {
        this.userservice.getValue('imageId' + this.image_type + this.resource_id).then((value) => {
          if (value != null) {
            // console.log('IMG loaded from store');
            this.src = value;
            return;
          }
        });
      }, 200);
    };
  }

  private loadImage(url: string): Observable<any> {
    // console.log('loadImage', url);
    // console.log('loadImage', this.access_token);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + this.access_token
    });
    return this.httpClient
      .get(url, { headers: headers, responseType: 'blob' }).pipe(
        map(e => {
          this.loaded(true);
          const uri = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e));
          if (this.toStore) {
            // console.log('toStore');
            // this.registerImage(e);
          }
          return uri;
        })
      );
  }

  private update() {
    this.loaded(false);
  }

  public onClickEvent() {
    this.OnClick.emit(this.resource_id);
  }

  private loaded(isLoaded: boolean) {
    if (isLoaded) {
      setTimeout(() => {
        this._renderer.addClass(this._elementRef.nativeElement, 'img-loaded');
      }, 500);
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, 'img-loaded');
    }
  }

  getSafeUrl(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
