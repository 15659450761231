import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const defaultItem = {
  id: 0,
  name: 'Log',
  link: '',
  start_date: '',
  end_date: '',
  upload_date: ''
};

@Component({
  selector: 'n7-log-preview',
  templateUrl: './n7-log-preview.component.html',
  styleUrls: ['./n7-log-preview.component.scss'],
})
export class N7LogPreviewComponent implements OnChanges {
  public item: any;

  @Input() set log(val: any) {
    this.item = (val !== undefined && val !== null) ? val : defaultItem;
    // console.log('this.item', this.item);
  }

  @Output() itemDelete = new EventEmitter()
  @Output() itemEdit = new EventEmitter()

  constructor(
    private iab: InAppBrowser
  ) {}

  ngOnChanges() {
    // console.log('onChange');
  }

  onItemClick(item) {
    // console.log('item', item);
  }

  onItemLinkClick(item) {
    // console.log('item', item);
    const url = item.link;
    // window.open(url, "_blank");
    this.iab.create(url, '_system');
  }

  onItemDeleteClick(item) {
    // TODO Aggiungere la conferma
    // console.log('item', item);
    this.itemDelete.emit(item.id);
  }

  onItemEditClick(item) {
    // TODO Aggiungere la conferma
    // console.log('item', item);
    this.itemEdit.emit(item.id);
  }
}
