import { Component, OnChanges, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component'
import * as _ from 'lodash';

export interface componentInteraction {
  remove(index: number);
}

export interface N7TextPickerOptions {
  key: string,
  label: string
}

@Component({
  selector: 'n7-text-picker',
  templateUrl: './n7-text-picker.component.html',
  styleUrls: ['./n7-text-picker.component.scss'],
})
export class N7TextPickerComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7TextPickerComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemIsOpen: boolean;
  public itemHasAddNew: boolean;
  public itemAddNewModalLabel: string = 'New item';
  public itemAddNewItems: any = [];
  public itemAddNewModalDismiss: string = 'Save';
  public itemHasBackdrop: boolean;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string;
  public itemUnit: string;
  public itemType: string;
  public itemPlaceholder: string;
  public itemPrefix: string;
  public itemHasAction: boolean;
  public itemActionDisabled: boolean;
  public itemActionLabel: string;
  public itemRegexp: string;
  public itemDisabled: boolean;
  public itemTitle: string;
  public itemOptions: N7TextPickerOptions[];

  private isFocusInsideComponent = false;
  private isComponentClicked = false;

  @HostListener('click')
  clickInside() {
    this.isFocusInsideComponent = true;
    this.isComponentClicked = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.isFocusInsideComponent && this.isComponentClicked) {
      this.onItemBlur({});
      this.isComponentClicked = false;
    }
    this.isFocusInsideComponent = false;
  }

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set isOpen(val: boolean) {
    this.itemIsOpen = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hasAddNew(val: boolean) {
    this.itemHasAddNew = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set addNewModalLabel(val: string) {
    this.itemAddNewModalLabel = (val !== undefined && val !== null) ? val : 'New item';
  }

  @Input() set addNewItems(val: any) {
    this.itemAddNewItems = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set addNewModalDismiss(val: string) {
    this.itemAddNewModalDismiss = (val !== undefined && val !== null) ? val : 'Save';
  }

  @Input() set hasBackdrop(val: boolean) {
    this.itemHasBackdrop = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null && val !== 'null' && val !== '') ? val : null;
  }

  @Input() set unit(val: string) {
    this.itemUnit = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : 'text';
  }

  @Input() set placeholder(val: string) {
    this.itemPlaceholder = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefix(val: string) {
    this.itemPrefix = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasAction(val: boolean) {
    this.itemHasAction = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set actionDisabled(val: boolean) {
    this.itemActionDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set actionLabel(val: string) {
    this.itemActionLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set regexp(val: string) {
    this.itemRegexp = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set options(val: []) {
    this.itemOptions = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Output() valueChange = new EventEmitter()

  @Output() itemAdd = new EventEmitter()

  constructor(
    public modalController: ModalController
  ) {
    this.itemType = 'text';
    this.itemTitle = '';
  }

  ngOnChanges() {
    this.checkErrors()
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  onItemClear(event) {
    this.itemValue = '';
    this.itemTitle = '';
    this.itemHasError = false;
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    this.checkErrors()
    this.valueChange.emit(this.itemValue);
  }

  onKeyUp(event) {
    this.itemValue = '';
    this.onItemChange(event);
  }

  checkErrors() {
    // console.log('checkErrors', this.itemTitle, this.itemValue);
    if ((this.itemTitle && this.itemTitle.length > 0) && (!this.itemValue || this.itemValue.length === 0)) {
      this.itemHasError = true;
      this.itemError = 'This item does\'t exist yet. Cancel and select "Add item" to create it.';
    }
    else {
      if (this.itemValue && this.itemRegexp) {
        const regexp = new RegExp(this.itemRegexp);
        const isValid = regexp.test(this.itemValue);
        if (isValid) {
          this.itemHasError = false;
        }
        else {
          this.itemHasError = true;
        }
      }
      else {
        this.itemHasError = false;
        this.itemTitle = this.getLabelFromKey(this.itemValue);
      }
    }
  }

  getLabelFromKey(key: string) {
    let label = '';
    const index = _.findIndex(this.itemOptions, (o) => { return o.key === key; });
    // console.log('getLabelFromKey', index);
    if (index >= 0) {
      label = this.itemOptions[index].label;
    }
    return (label) ? label : this.itemTitle;
  }

  onItemFocus(event) {
    this.isFocusInsideComponent = false;
    this.isComponentClicked = true;
    this.itemIsOpen = true;
  }

  onItemBlur(event) {
    this.itemIsOpen = false;
    this.checkErrors();
  }

  onItemSelected(key: string) {
    const index = _.findIndex(this.itemOptions, (o) => { return o.key === key; });
    if (index >= 0) {
      this.itemIsOpen = false;
      this.itemValue = key;
      this.itemTitle = this.itemOptions[index].label;
      this.valueChange.emit(this.itemValue);
    }
    else {
      this.itemIsOpen = false;
    }
    this.onItemBlur({});
  }

  onItemAdd() {
    this.presentNewItemModal({});
  }

  async presentNewItemModal(ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: this.itemAddNewModalLabel,
        formItems: this.itemAddNewItems,
        dismissLabel: this.itemAddNewModalDismiss
      },
      cssClass: 'modal-form'
    });

    modal.onDidDismiss().then((data) => {
      this.itemIsOpen = false;
      if (data !== null) {
        if (data.data && data.data.formItems) {
          this.itemAdd.emit(data.data.formItems);
        }
      }

    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  filterOption(option: any, args: any) {
    if (args && (args.length > 0)) {
      const filterText = args.toLowerCase();
      return ((option.label.toLowerCase()).startsWith(filterText));
    }
    return true;
  }

}
